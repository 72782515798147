import * as yup from 'yup';
import { MESSAGES } from '../messages';

const locationFieldValidate = yup
  .object()
  .shape({
    id: yup
      .string()
      .max(255, MESSAGES.MAX_LENGTH('255'))
      .required(MESSAGES.REQUIRE_MESSAGE),
    label: yup
      .string()
      .max(255, MESSAGES.MAX_LENGTH('255'))
      .required(MESSAGES.REQUIRE_MESSAGE),
  })
  .nullable()
  .required(MESSAGES.REQUIRE_MESSAGE);

export const editCitiesSchema = yup.object().shape({
  cities: yup.array().of(
    yup.object().shape({
      latitude: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
      longitude: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
      countryCode: locationFieldValidate,
      stateCode: locationFieldValidate,
      cityCode: locationFieldValidate,
    }),
  ),
});
