import {
  CircularIndeterminate,
  OptionsCaseLayout,
  TextFieldFormik,
} from '../../components';
import { FieldArray, Form, Formik, useField } from 'formik';
import {
  ButtonSaveChangesCSS,
  FieldArrayRow,
  FieldArrayRowInner,
} from './styles';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CloseModalIcon } from '../../components/Icons';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  ICity,
  IFormikSettingsCitiesFormProps,
  ISettingsCitiesFormProps,
} from './types';
import { editCitiesSchema } from '../../validation';
import {
  useBulkUpdateCityMutation,
  useCountriesQuery,
  useOutscrapperLocationsLazyQuery as useOutscrapperLocationsStateLazyQuery,
  useOutscrapperLocationsLazyQuery as useOutscrapperLocationsCityLazyQuery,
  useParseCityMutation,
} from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { CITIES } from '../../graphql/queries/cities.gqll';
import dayjs from 'dayjs';
import { AutocompleteFormik } from '../../components/UI/AutocompleteFormik/AutocompleteFormik';
import { useCallback, useEffect } from 'react';

export const SettingsCitiesForm = ({
  citiesOption,
}: ISettingsCitiesFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateCity, { loading }] = useBulkUpdateCityMutation({
    onCompleted: () =>
      onOpenAlert({ title: t('web_setting_list_cities_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [CITIES],
  });

  const initialValues = {
    cities:
      citiesOption?.length < 1
        ? [
            {
              label: '',
              latitude: 0,
              longitude: 0,
              countryCode: null,
              stateCode: null,
              cityCode: null,
            },
          ]
        : citiesOption,
  };

  const handleSubmit = (values: IFormikSettingsCitiesFormProps) => {
    const deleteItems: any = [];

    citiesOption.forEach((item) => {
      if (
        !values.cities
          .map((i) => i.id)
          .filter((i) => i)
          .includes(item.id)
      ) {
        deleteItems.push({
          title: item.label || '',
          id: Number(item.id),
          latitude: Number(item.latitude),
          longitude: Number(item.longitude),
          parserCode: '',
          countryCode: '',
          isDelete: true,
        });
      }
    });

    const updateItems = values.cities.map((item) => {
      const idParams = item.id
        ? {
            id: Number(item.id),
          }
        : {};

      const parserCode = `${item.countryCode?.id}>${item.stateCode?.id}>${item.cityCode?.id}`;

      return {
        title: item.cityCode?.id,
        latitude: Number(item.latitude),
        longitude: Number(item.longitude),
        parserCode: parserCode,
        countryCode: item.countryCode?.id,
        ...idParams,
      };
    });

    updateCity({
      variables: {
        citiesData: {
          list: [...updateItems, ...deleteItems],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout title={t('web_setting_case_label_city')}>
      <Box mb={'8px'} sx={{ display: 'flex', gap: '16px' }}>
        <Typography variant={'body1'} width={'200px'} flexShrink={0}>
          {t('web_setting_label_contry_name')}
        </Typography>
        <Typography variant={'body1'} width={'200px'} flexShrink={0}>
          {t('web_setting_label_state_name')}
        </Typography>
        <Typography variant={'body1'} width={'200px'} flexShrink={0}>
          {t('web_setting_label_city_name')}
        </Typography>

        <Typography variant={'body1'} width={'150px'} flexShrink={0}>
          {t('web_setting_label_city_longitude')}
        </Typography>
        <Typography variant={'body1'} width={'150px'} flexShrink={0}>
          {t('web_setting_label_city_latitude')}
        </Typography>

        <Box sx={{ width: '100%', maxWidth: '100px' }} />
      </Box>
      <Formik<IFormikSettingsCitiesFormProps>
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={editCitiesSchema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <FieldArray name={'cities'}>
                {({ remove, push }) => {
                  const handleRemove = (index: number) => {
                    remove(index);
                  };
                  return (
                    <FieldArrayRow>
                      {values.cities.map((city, index) => {
                        return (
                          <CityRow
                            city={city}
                            index={index}
                            key={index}
                            remove={handleRemove}
                          />
                        );
                      })}
                      <Stack direction={'row'} spacing={1}>
                        <Button
                          onClick={() =>
                            push({
                              label: '',
                              latitude: '',
                              longitude: '',
                              countryCode: null,
                              stateCode: null,
                              cityCode: null,
                            })
                          }
                          size={'large'}
                          sx={{ width: '100%', maxWidth: '160px' }}
                        >
                          {t('web_setting_action_city_add')}
                        </Button>
                        <ButtonSaveChangesCSS
                          type='submit'
                          size={'large'}
                          loading={loading}
                          disabled={!dirty}
                        >
                          {t('web_action_save')}
                        </ButtonSaveChangesCSS>
                      </Stack>
                    </FieldArrayRow>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};

const CityRow = ({
  city,
  index,
  remove,
}: {
  city: ICity;
  index: number;
  remove: (index: number) => void;
}) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();
  const [field, meta, helpers] = useField(`cities.${index}`);
  const { setValue } = helpers;

  const [parseCity, { loading: paseLoading }] = useParseCityMutation({
    onCompleted: () => onOpenAlert({ title: t('web_parsed_processing') }),
    onError: (error) =>
      onOpenAlert({
        title: error.message,
        error: true,
      }),
    refetchQueries: [CITIES],
  });

  const { data: countriesData } = useCountriesQuery();

  const [outscrapperState, { data: stateData, loading: stateLoading }] =
    useOutscrapperLocationsStateLazyQuery();
  const [outscrapperCity, { data: cityData, loading: cityLoading }] =
    useOutscrapperLocationsCityLazyQuery();

  const countryCodeValue = field.value?.countryCode;
  const stateCodeValue = field.value?.stateCode;

  useEffect(() => {
    if (!countryCodeValue?.id) return;

    outscrapperState({
      variables: {
        input: {
          countryCode: countryCodeValue?.id,
        },
      },
    });
  }, [countryCodeValue]);

  useEffect(() => {
    if (!stateCodeValue?.id) return;
    outscrapperCity({
      variables: {
        input: {
          countryCode: countryCodeValue?.id,
          stateCode: stateCodeValue?.id,
        },
      },
    });
  }, [stateCodeValue]);

  const handleClearByCountry = useCallback(() => {
    setValue({ ...field.value, stateCode: null, cityCode: null });
  }, [setValue, field.value]);

  const handleClearByState = useCallback(() => {
    setValue({ ...field.value, cityCode: null });
  }, [setValue, field.value]);

  const isParseProcessing = city.parserStatus === 'PROCESSING';
  const isParseError = city.parserStatus === 'ERROR';
  const isParseCompleted = city.parserStatus === 'COMPLETED';

  const parsedStatus = isParseCompleted
    ? t('web_completed')
    : isParseProcessing
    ? t('web_processing')
    : isParseError
    ? t('web_error')
    : t('web_none');

  const countriesOptions =
    countriesData?.countries?.map((country) => ({
      id: country.code,
      label: country.code,
    })) || [];

  const statesOptions =
    stateData?.outscrapperLocations?.map((location) => ({
      id: location.name,
      label: location.name,
    })) || [];

  const citiesOptions =
    cityData?.outscrapperLocations?.map((location) => ({
      id: location.name,
      label: location.name,
    })) || [];

  return (
    <FieldArrayRowInner key={index}>
      <Box width={'200px'} flexShrink={0}>
        <AutocompleteFormik
          label=''
          name={`cities.${index}.countryCode`}
          options={countriesOptions}
          multiple={false}
          sx={{ width: '200px', minWidth: '200px' }}
          onChange={handleClearByCountry}
        />
      </Box>

      <Box width={'200px'} flexShrink={0}>
        {stateLoading ? (
          <CircularIndeterminate sx={{ height: '50px' }} />
        ) : countryCodeValue && !!statesOptions.length ? (
          <AutocompleteFormik
            label=''
            name={`cities.${index}.stateCode`}
            options={statesOptions}
            multiple={false}
            sx={{ width: '200px', minWidth: '200px' }}
            onChange={handleClearByState}
          />
        ) : null}
      </Box>

      <Box width={'200px'} flexShrink={0}>
        {cityLoading ? (
          <CircularIndeterminate sx={{ height: '50px' }} />
        ) : stateCodeValue && !!citiesOptions.length ? (
          <AutocompleteFormik
            label=''
            name={`cities.${index}.cityCode`}
            options={citiesOptions}
            multiple={false}
            sx={{ width: '200px', minWidth: '200px' }}
          />
        ) : null}
      </Box>

      <TextFieldFormik
        name={`cities.${index}.longitude`}
        width={'150px'}
        sx={{ minWidth: '150px' }}
        size={'large'}
        type={'number'}
      />
      <TextFieldFormik
        name={`cities.${index}.latitude`}
        width={'150px'}
        sx={{ minWidth: '150px' }}
        size={'large'}
        type={'number'}
      />

      {city?.id ? (
        <Box
          sx={{
            display: 'flex',
            height: '50px',
            alignItems: 'center',
          }}
        >
          <ButtonSaveChangesCSS
            disabled={isParseProcessing}
            loading={paseLoading}
            onClick={() =>
              parseCity({
                variables: {
                  cityId: Number(city?.id),
                },
              })
            }
          >
            {t('web_parsing_button')}
          </ButtonSaveChangesCSS>
          <Typography ml={'16px'} width={'140px'}>
            {`${t('web_last_parsed_date')}: ${
              city?.parserLastParsedDate
                ? dayjs(+city?.parserLastParsedDate).format('DD.MM.YYYY')
                : t('web_no_parsed')
            }.`}
          </Typography>
          <Typography width={'140px'} ml={'16px'}>{`${t(
            'web_parsed_status',
          )}: ${parsedStatus}`}</Typography>
        </Box>
      ) : null}

      <IconButton
        sx={{ mb: '6px' }}
        size={'large'}
        onClick={() => remove(index)}
      >
        <CloseModalIcon />
      </IconButton>
    </FieldArrayRowInner>
  );
};
