import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
  Upload: any;
};

export type AddOrUpdateEventOfferDishes = {
  eventOfferId: Scalars['Int'];
  offerDishes: Array<EventOfferDishInput>;
};

export type AddOrUpdateOfferDishInput = {
  offerDishes: Array<OfferDishInput>;
  offerId: Scalars['Int'];
};

/** advice */
export type Advice = {
  __typename?: 'Advice';
  id: Scalars['Int'];
  text: Scalars['String'];
};

export enum AllowedMutation {
  Register = 'REGISTER',
  SetAuthData = 'SET_AUTH_DATA',
  UpdatePassword = 'UPDATE_PASSWORD',
  UpdatePhone = 'UPDATE_PHONE',
}

export type Auth = {
  __typename?: 'Auth';
  accessToken: Scalars['String'];
  user: User;
};

export type Brand = {
  __typename?: 'Brand';
  description: Scalars['String'];
  id: Scalars['Int'];
  image: Image;
  isActive: Scalars['Boolean'];
  restaurantsCount?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type BrandWithCountResponse = {
  __typename?: 'BrandWithCountResponse';
  count: Scalars['Int'];
  rows: Array<Brand>;
};

export type BulkAdviceInput = {
  list: Array<BulkAdviceItemInput>;
};

export type BulkAdviceItemInput = {
  id?: InputMaybe<Scalars['Int']>;
  isDelete?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type BulkCityInput = {
  list: Array<BulkCityItemInput>;
};

export type BulkCityItemInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isDelete?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  parserCode?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BulkCuisineInput = {
  list: Array<BulkCuisineItemInput>;
};

export type BulkCuisineItemInput = {
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  isDelete?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BulkDishCategoryInput = {
  list: Array<BulkDishCategoryItemInput>;
};

export type BulkDishCategoryItemInput = {
  brandId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isDelete?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BulkEventThemeInput = {
  list: Array<BulkEventThemeItemInput>;
};

export type BulkEventThemeItemInput = {
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  isDelete?: InputMaybe<Scalars['Boolean']>;
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BulkSettingInput = {
  list: Array<BulkSettingItemInput>;
};

export type BulkSettingItemInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type BulkTranslateInput = {
  list: Array<BulkTranslateItemInput>;
};

export type BulkTranslateItemInput = {
  images: Array<TranslateImageInput>;
  isDelete?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  strings: Array<TranslateStringInput>;
  texts: Array<TranslateTextInput>;
};

export type ChangePasswordInput = {
  password: Scalars['String'];
  /** Phone number in format: +111111111111 */
  phone?: InputMaybe<Scalars['String']>;
};

/** city */
export type City = {
  __typename?: 'City';
  countryCode?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  parserCode?: Maybe<Scalars['String']>;
  parserLastParsedDate?: Maybe<Scalars['String']>;
  parserStatus?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ClusteredRestaurantGroupResponse = {
  __typename?: 'ClusteredRestaurantGroupResponse';
  count: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  restaurantId?: Maybe<Scalars['Int']>;
  restaurants: Array<Restaurant>;
};

export type ConfirmationCode = {
  __typename?: 'ConfirmationCode';
  /** Code from SMS will expired after this time. Timestamp in milliseconds. */
  expiredAt: Scalars['Timestamp'];
  mutation: Scalars['String'];
  phone: Scalars['String'];
  /** You can resend SMS after this time. Timestamp in milliseconds. */
  resendAvailableAt: Scalars['Timestamp'];
};

export type ConfirmationCodeVerificationDataEntity = {
  __typename?: 'ConfirmationCodeVerificationDataEntity';
  token: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateBrandInput = {
  description: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  title: Scalars['String'];
};

export type CreateCityInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  parserCode?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateCuisineInput = {
  image: Scalars['Upload'];
  title: Scalars['String'];
};

export type CreateDishCategoryInput = {
  brandId: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateDishInput = {
  amountPersons?: InputMaybe<Scalars['Int']>;
  brandId: Scalars['Int'];
  calories?: InputMaybe<Scalars['Int']>;
  cookingTime?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  dishCategoryId: Scalars['Int'];
  image: Scalars['Upload'];
  price: Scalars['Float'];
  title: Scalars['String'];
  volume?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type CreateEventInput = {
  amountPersons: Scalars['Int'];
  budgetPerPerson: Scalars['Float'];
  centerPointLatitude?: InputMaybe<Scalars['Float']>;
  centerPointLongitude?: InputMaybe<Scalars['Float']>;
  cityId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  cuisineId: Scalars['Int'];
  endAt?: InputMaybe<Scalars['String']>;
  eventThemeId: Scalars['Int'];
  name: Scalars['String'];
  offerId?: InputMaybe<Scalars['Int']>;
  radiusInMiles?: InputMaybe<Scalars['Int']>;
  ratingFrom?: InputMaybe<Scalars['Float']>;
  repeatCount?: InputMaybe<Scalars['Int']>;
  repeatOnceAt?: InputMaybe<Repeat_Frequency_Enum>;
  showAfter?: InputMaybe<Scalars['String']>;
  startAt: Scalars['String'];
  targetRestaurantId?: InputMaybe<Scalars['Int']>;
  tipPercentage?: InputMaybe<Scalars['Int']>;
  type: Event_Type_Enum;
};

export type CreateEventThemeInput = {
  image: Scalars['Upload'];
  isFavorite: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CreateLocaleInput = {
  code: Scalars['String'];
  title: Scalars['String'];
};

export type CreateManagerInput = {
  brandId?: InputMaybe<Scalars['Float']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  photo?: InputMaybe<ImageInput>;
  position?: InputMaybe<Scalars['String']>;
  roles: Array<Roles>;
};

export type CreateNotificationInput = {
  text: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['Int'];
};

export type CreateOfferInput = {
  amountPersons: Scalars['Int'];
  budgetPerPerson: Scalars['Float'];
  cuisinesIds: Array<Scalars['Int']>;
  description: Scalars['String'];
  eventThemesIds: Array<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  restaurantsIds: Array<Scalars['Int']>;
  scheduleType: Schedule_Enum;
  workDays: Array<CreateWorkdayInput>;
};

export type CreateRegistrationApplicationInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  restaurantName: Scalars['String'];
};

export type CreateRestaurantInput = {
  address?: InputMaybe<Scalars['String']>;
  availableSeatsNumber?: InputMaybe<Scalars['Int']>;
  averageCost?: InputMaybe<Scalars['Float']>;
  brandId: Scalars['Int'];
  cityId: Scalars['Int'];
  cuisinesIds: Array<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  priceLevel: Scalars['Int'];
  scheduleType: Schedule_Enum;
  secondImage?: InputMaybe<Scalars['Upload']>;
  workDays: Array<CreateWorkdayInput>;
};

export type CreateRestaurantReviewInput = {
  accurateRating: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  cuisineRating: Scalars['Int'];
  eventMatchRating: Scalars['Int'];
  eventOfferId: Scalars['Int'];
  restaurantId: Scalars['Int'];
  serviceRating: Scalars['Int'];
  title: Scalars['String'];
};

export type CreateWorkdayInput = {
  day: Workday_Enum;
  from: Scalars['Int'];
  isActive: Scalars['Boolean'];
  to: Scalars['Int'];
};

/** cuisine */
export type Cuisine = {
  __typename?: 'Cuisine';
  id: Scalars['Int'];
  image: Image;
  title: Scalars['String'];
};

/** dish */
export type Dish = {
  __typename?: 'Dish';
  amountPersons?: Maybe<Scalars['Int']>;
  brandId: Scalars['Int'];
  calories?: Maybe<Scalars['Int']>;
  cookingTime?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  dishCategory: DishCategory;
  dishCategoryId: Scalars['Int'];
  id: Scalars['Int'];
  image: Image;
  price: Scalars['Float'];
  title: Scalars['String'];
  volume?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

/** dish category */
export type DishCategory = {
  __typename?: 'DishCategory';
  brandId: Scalars['Int'];
  countDishes?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type DishesArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  dishCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  restaurantsIds?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
};

export type DishesInRestaurantInput = {
  addDishIds?: InputMaybe<Array<Scalars['Int']>>;
  removeDishIds?: InputMaybe<Array<Scalars['Int']>>;
  restaurantId: Scalars['Int'];
};

export type DishesResponse = {
  __typename?: 'DishesResponse';
  count: Scalars['Int'];
  rows: Array<Dish>;
};

export enum Event_Offer_Status_Enum {
  Active = 'ACTIVE',
  DeclinedByRestaurant = 'DECLINED_BY_RESTAURANT',
  DeclinedByUser = 'DECLINED_BY_USER',
  New = 'NEW',
  Sended = 'SENDED',
  SuccessfullyClosed = 'SUCCESSFULLY_CLOSED',
  UnsuccessfullyClosed = 'UNSUCCESSFULLY_CLOSED',
}

export enum Event_Offer_Status_Enum_As_User {
  Active = 'ACTIVE',
  DeclinedByRestaurant = 'DECLINED_BY_RESTAURANT',
  DeclinedByUser = 'DECLINED_BY_USER',
  Sended = 'SENDED',
  SuccessfullyClosed = 'SUCCESSFULLY_CLOSED',
  UnsuccessfullyClosed = 'UNSUCCESSFULLY_CLOSED',
}

export enum Event_Offer_Type_Enum {
  Direct = 'DIRECT',
  General = 'GENERAL',
  Mismatch = 'MISMATCH',
  OtherDate = 'OTHER_DATE',
}

export enum Event_Status_Enum {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  InWork = 'IN_WORK',
}

export enum Event_Type_Enum {
  Delivery = 'DELIVERY',
  OnSite = 'ON_SITE',
  Pickup = 'PICKUP',
}

export type EditBrandInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  acceptedEventOffer?: Maybe<EventOffer>;
  amountPersons: Scalars['Int'];
  budgetPerPerson: Scalars['Float'];
  centerPointLatitude: Scalars['Float'];
  centerPointLongitude: Scalars['Float'];
  city: City;
  cityId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  cuisine: Cuisine;
  cuisineId: Scalars['Int'];
  endAt?: Maybe<Scalars['String']>;
  eventOffers?: Maybe<Array<EventOffer>>;
  eventTheme: EventTheme;
  eventThemeId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  publishedAt: Scalars['String'];
  radiusInMiles: Scalars['Int'];
  randomAdvice?: Maybe<Scalars['String']>;
  ratingFrom?: Maybe<Scalars['Float']>;
  repeatCount?: Maybe<Scalars['Int']>;
  repeatOnceAt?: Maybe<Repeat_Frequency_Enum>;
  showAfter?: Maybe<Scalars['String']>;
  startAt: Scalars['String'];
  status: Event_Status_Enum;
  targetRestaurant?: Maybe<Restaurant>;
  targetRestaurantId?: Maybe<Scalars['Int']>;
  tipPercentage?: Maybe<Scalars['Int']>;
  type: Event_Type_Enum;
  updatedAt?: Maybe<Scalars['String']>;
  user: User;
  userId: Scalars['Int'];
};

export type EventOffer = {
  __typename?: 'EventOffer';
  brandId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  event: Event;
  eventId: Scalars['Int'];
  eventOfferDishes?: Maybe<Array<EventOfferDish>>;
  id: Scalars['Int'];
  isDirect?: Maybe<Scalars['Boolean']>;
  myEventReview?: Maybe<RestaurantReview>;
  nearestDate?: Maybe<Scalars['String']>;
  restaurantReview?: Maybe<RestaurantReview>;
  selectedRestaurant?: Maybe<Restaurant>;
  selectedRestaurantId?: Maybe<Scalars['Int']>;
  sendedAt?: Maybe<Scalars['String']>;
  status: Event_Offer_Status_Enum;
  suitableRestaurantsIds: Array<Scalars['Int']>;
  total?: Maybe<Scalars['Float']>;
  type: Event_Offer_Type_Enum;
};

export type EventOfferDish = {
  __typename?: 'EventOfferDish';
  dish: Dish;
  dishId: Scalars['Int'];
  eventOfferId: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type EventOfferDishInput = {
  dishId: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** event theme */
export type EventTheme = {
  __typename?: 'EventTheme';
  availableSeatsNumber?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  image: Image;
  isFavorite: Scalars['Boolean'];
  title: Scalars['String'];
};

export type EventsWithCountResponse = {
  __typename?: 'EventsWithCountResponse';
  count: Scalars['Int'];
  rows: Array<Event>;
};

export type FavouriteRestaurantInput = {
  isFavorite: Scalars['Boolean'];
  restaurantId: Scalars['Int'];
};

export type FilterOfferOrderAsUserInput = {
  day: Workday_Enum;
  timeNumber: Scalars['Int'];
};

export type FindFilter = {
  brandId?: InputMaybe<Scalars['Int']>;
};

export type FindOutscrapperLocationsInput = {
  countryCode: Scalars['String'];
  stateCode?: InputMaybe<Scalars['String']>;
};

export type FromToInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type GetBrandsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GetEventOfferReviewInput = {
  eventOfferId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type GetEventOffersAsUserInput = {
  eventId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<Event_Offer_Status_Enum_As_User>>;
};

export type GetEventOffersInput = {
  status?: InputMaybe<Array<Event_Offer_Status_Enum>>;
};

export type GetEventsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<Event_Status_Enum>>;
};

export type GetOffersAsAdminInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort: OfferOrderInput;
};

export type GetOffersAsManagerInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort: OfferOrderInput;
  status?: InputMaybe<Offer_Availability_Enum>;
};

export type GetOffersAsUserInput = {
  amountPersonsFrom?: InputMaybe<Scalars['Int']>;
  amountPersonsTo?: InputMaybe<Scalars['Int']>;
  cityId?: InputMaybe<Scalars['Int']>;
  cuisinesIds?: InputMaybe<Array<Scalars['Int']>>;
  eventThemesIds?: InputMaybe<Array<Scalars['Int']>>;
  favoritesOnly?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FilterOfferOrderAsUserInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  radiusInMiles?: InputMaybe<Scalars['Int']>;
  resturantId?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort: OfferOrderAsUserInput;
  userLatitude?: InputMaybe<Scalars['Float']>;
  userLongitude?: InputMaybe<Scalars['Float']>;
};

export type GetRestaurantReviewsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  restaurantId: Scalars['Int'];
};

export type GetRestaurantsAsUserInput = {
  cityId?: InputMaybe<Scalars['Int']>;
  clusteringDistanceThreshold?: InputMaybe<Scalars['Float']>;
  cuisinesIds?: InputMaybe<Array<Scalars['Int']>>;
  favoritesOnly: Scalars['Boolean'];
  ids?: InputMaybe<Array<Scalars['Int']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  radiusInMiles?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  userLatitude?: InputMaybe<Scalars['Float']>;
  userLongitude?: InputMaybe<Scalars['Float']>;
};

export type GetRestaurantsInput = {
  brandId?: InputMaybe<Scalars['Int']>;
  cityId?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortInput>;
};

export type Image = {
  __typename?: 'Image';
  height: Scalars['Int'];
  id: Scalars['Int'];
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type ImageInput = {
  remove?: InputMaybe<Scalars['Boolean']>;
  upload?: InputMaybe<Scalars['Upload']>;
};

/** locale */
export type Locale = {
  __typename?: 'Locale';
  /** Language ISO 639-1 codes. Example: en, uk */
  code: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
  translates: Array<Translate>;
};

export type Manager = {
  __typename?: 'Manager';
  brand?: Maybe<Brand>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  photo?: Maybe<Image>;
  position?: Maybe<Scalars['String']>;
  roles: Array<Roles>;
};

export type ManagerAuth = {
  __typename?: 'ManagerAuth';
  accessToken: Scalars['String'];
  manager: Manager;
};

export type ManagerSignInInput = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type ManagerUpdateMeInput = {
  brandId?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<ImageInput>;
  position?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Roles>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrUpdateEventOfferDishes: Array<EventOfferDish>;
  addOrUpdateOfferDishes: Array<OfferDish>;
  addTranslate: Translate;
  bulkUpdateAdvice: Array<Advice>;
  bulkUpdateCity: Scalars['Boolean'];
  bulkUpdateCuisine: Scalars['Boolean'];
  bulkUpdateDishCategory: Scalars['Boolean'];
  bulkUpdateEventTheme: Scalars['Boolean'];
  bulkUpdateSetting: Array<Setting>;
  bulkUpdateTranslate: Array<Translate>;
  changeActiveStatusUserNotificationDevice: Scalars['Boolean'];
  closeEvent: Scalars['Boolean'];
  createBrand: Brand;
  createCity: City;
  createCuisine: Cuisine;
  createDish: Dish;
  createDishCategory: DishCategory;
  createEvent: Event;
  createEventTheme: EventTheme;
  createLocale: Locale;
  createManager: Manager;
  createNotification: Scalars['Boolean'];
  createOffer: Offer;
  createRegistrationApplication: RegistrationApplication;
  createRemindEventOfferNotification: Scalars['Boolean'];
  createRestaurant: Restaurant;
  createRestaurantReview: RestaurantReview;
  dishesInRestaurant: Scalars['Boolean'];
  favouriteRestaurant: Scalars['Boolean'];
  managerRestorePassword: Scalars['Boolean'];
  managerSignIn: ManagerAuth;
  managerUpdateMe: Manager;
  oAuth: Auth;
  offersToFavorites: Scalars['Boolean'];
  parseCity: City;
  register: Auth;
  registerUserNotificationDevice: Scalars['Boolean'];
  removeBrand: Scalars['Boolean'];
  removeCity: Scalars['Boolean'];
  removeCuisine: Scalars['Boolean'];
  removeDish: Scalars['Boolean'];
  removeDishCategory: Scalars['Boolean'];
  removeEvent: Scalars['Boolean'];
  removeEventTheme: Scalars['Boolean'];
  removeLocale: Scalars['Boolean'];
  removeManager: Manager;
  removeMe: Scalars['Boolean'];
  removeRegistrationApplication: Scalars['Boolean'];
  removeRestaurant: Scalars['Boolean'];
  removeTranslate: Scalars['Boolean'];
  removeUserNotificationDevice: Scalars['Boolean'];
  sendConfirmationCode: ConfirmationCode;
  setAuthData: User;
  signIn: Auth;
  signInAsManager: ManagerAuth;
  switchBrandActiveStatus: Brand;
  switchRestaurantClosedStatus: Restaurant;
  updateBrand: Brand;
  updateCity: City;
  updateCuisine: Cuisine;
  updateDish: Dish;
  updateDishCategory: DishCategory;
  updateEvent: Event;
  updateEventOffer: EventOffer;
  updateEventOfferAsUser: EventOffer;
  updateEventTheme: EventTheme;
  updateLocale: Locale;
  updateManager: Manager;
  updateMe: User;
  updateOffer: Offer;
  updatePassword: User;
  updatePhone: User;
  updateRestaurant: Restaurant;
  updateTranslate: Translate;
  /** Return token to access to mutation */
  verifyConfirmationCode: ConfirmationCodeVerificationDataEntity;
};

export type MutationAddOrUpdateEventOfferDishesArgs = {
  input: AddOrUpdateEventOfferDishes;
};

export type MutationAddOrUpdateOfferDishesArgs = {
  input: AddOrUpdateOfferDishInput;
};

export type MutationAddTranslateArgs = {
  translateData: TranslateInput;
};

export type MutationBulkUpdateAdviceArgs = {
  advicesData: BulkAdviceInput;
};

export type MutationBulkUpdateCityArgs = {
  citiesData: BulkCityInput;
};

export type MutationBulkUpdateCuisineArgs = {
  cuisinesData: BulkCuisineInput;
};

export type MutationBulkUpdateDishCategoryArgs = {
  dishCategoriesData: BulkDishCategoryInput;
};

export type MutationBulkUpdateEventThemeArgs = {
  eventThemesData: BulkEventThemeInput;
};

export type MutationBulkUpdateSettingArgs = {
  settingsData: BulkSettingInput;
};

export type MutationBulkUpdateTranslateArgs = {
  translatesData: BulkTranslateInput;
};

export type MutationChangeActiveStatusUserNotificationDeviceArgs = {
  oneSignalDeviceId: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MutationCloseEventArgs = {
  id: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationCreateBrandArgs = {
  createBrandInput: CreateBrandInput;
};

export type MutationCreateCityArgs = {
  createCityInput: CreateCityInput;
};

export type MutationCreateCuisineArgs = {
  createCuisineInput: CreateCuisineInput;
};

export type MutationCreateDishArgs = {
  createDishInput: CreateDishInput;
};

export type MutationCreateDishCategoryArgs = {
  createDishCategoryInput: CreateDishCategoryInput;
};

export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};

export type MutationCreateEventThemeArgs = {
  createEventThemeInput: CreateEventThemeInput;
};

export type MutationCreateLocaleArgs = {
  createLocaleInput: CreateLocaleInput;
};

export type MutationCreateManagerArgs = {
  createManagerInput: CreateManagerInput;
};

export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};

export type MutationCreateOfferArgs = {
  createOfferInput: CreateOfferInput;
};

export type MutationCreateRegistrationApplicationArgs = {
  createRegistrationApplicationInput: CreateRegistrationApplicationInput;
};

export type MutationCreateRemindEventOfferNotificationArgs = {
  eventOfferId: Scalars['Int'];
};

export type MutationCreateRestaurantArgs = {
  createRestaurantInput: CreateRestaurantInput;
};

export type MutationCreateRestaurantReviewArgs = {
  input: CreateRestaurantReviewInput;
};

export type MutationDishesInRestaurantArgs = {
  dishesInRestaurantInput: DishesInRestaurantInput;
};

export type MutationFavouriteRestaurantArgs = {
  favouriteRestaurantInput: FavouriteRestaurantInput;
};

export type MutationManagerRestorePasswordArgs = {
  email: Scalars['String'];
};

export type MutationManagerSignInArgs = {
  managerSignInInput: ManagerSignInInput;
};

export type MutationManagerUpdateMeArgs = {
  managerUpdateMeInput: ManagerUpdateMeInput;
};

export type MutationOAuthArgs = {
  input: OAuthInput;
};

export type MutationOffersToFavoritesArgs = {
  updates: UpdateOffersIsFavoriteStatusInput;
};

export type MutationParseCityArgs = {
  cityId: Scalars['Int'];
};

export type MutationRegisterArgs = {
  registerInput: RegisterInput;
  token: Scalars['String'];
};

export type MutationRegisterUserNotificationDeviceArgs = {
  oneSignalDeviceId: Scalars['String'];
};

export type MutationRemoveBrandArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveCityArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveCuisineArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveDishArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveDishCategoryArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveEventArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveEventThemeArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveLocaleArgs = {
  id: Scalars['Float'];
};

export type MutationRemoveManagerArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveRegistrationApplicationArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveRestaurantArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveTranslateArgs = {
  key: Scalars['String'];
};

export type MutationRemoveUserNotificationDeviceArgs = {
  oneSignalDeviceId: Scalars['String'];
};

export type MutationSendConfirmationCodeArgs = {
  sendConfirmationCodeInput: SendConfirmationCodeInput;
};

export type MutationSetAuthDataArgs = {
  input: RegisterInput;
  token: Scalars['String'];
};

export type MutationSignInArgs = {
  signInInput: SignInInput;
};

export type MutationSignInAsManagerArgs = {
  id: Scalars['Int'];
};

export type MutationSwitchBrandActiveStatusArgs = {
  id: Scalars['Int'];
};

export type MutationSwitchRestaurantClosedStatusArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateBrandArgs = {
  input: EditBrandInput;
};

export type MutationUpdateCityArgs = {
  updateCityInput: UpdateCityInput;
};

export type MutationUpdateCuisineArgs = {
  updateCuisineInput: UpdateCuisineInput;
};

export type MutationUpdateDishArgs = {
  updateDishInput: UpdateDishInput;
};

export type MutationUpdateDishCategoryArgs = {
  updateDishCategoryInput: UpdateDishCategoryInput;
};

export type MutationUpdateEventArgs = {
  updateEventInput: UpdateEventInput;
};

export type MutationUpdateEventOfferArgs = {
  updates: UpdateEventOfferInput;
};

export type MutationUpdateEventOfferAsUserArgs = {
  updates: UpdateEventOfferAsUserInput;
};

export type MutationUpdateEventThemeArgs = {
  updateEventThemeInput: UpdateEventThemeInput;
};

export type MutationUpdateLocaleArgs = {
  updateLocaleInput: UpdateLocaleInput;
};

export type MutationUpdateManagerArgs = {
  updateManagerInput: UpdateManagerInput;
};

export type MutationUpdateMeArgs = {
  updateMeInput: UpdateMeInput;
};

export type MutationUpdateOfferArgs = {
  updateOfferInput: UpdateOfferInput;
};

export type MutationUpdatePasswordArgs = {
  token: Scalars['String'];
  updatePasswordInput: ChangePasswordInput;
};

export type MutationUpdatePhoneArgs = {
  input: UpdatePhoneInput;
  token: Scalars['String'];
};

export type MutationUpdateRestaurantArgs = {
  updateRestaurantInput: UpdateRestaurantInput;
};

export type MutationUpdateTranslateArgs = {
  translateData: TranslateInput;
};

export type MutationVerifyConfirmationCodeArgs = {
  input: VerifyConfirmationCodeInput;
};

export type NewEventOffersCount = {
  __typename?: 'NewEventOffersCount';
  count: Scalars['Int'];
};

export type OAuthInput = {
  provider: Providers;
  token: Scalars['String'];
};

export enum Offer_Availability_Enum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Stoped = 'STOPED',
}

export enum Order_Offer_As_User_By_Enum {
  Cost = 'COST',
  CreatedAt = 'CREATED_AT',
  Distance = 'DISTANCE',
  Popularity = 'POPULARITY',
}

export enum Order_Offer_By_Enum {
  CreatedAt = 'CREATED_AT',
  IsFavorite = 'IS_FAVORITE',
}

export type Offer = {
  __typename?: 'Offer';
  amountPersons: Scalars['Int'];
  brand: Brand;
  budgetPerPerson: Scalars['Float'];
  createdAt: Scalars['String'];
  cuisines: Array<Cuisine>;
  description: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  eventThemes: Array<EventTheme>;
  id: Scalars['Int'];
  image?: Maybe<Image>;
  isFavorite: Scalars['Boolean'];
  name: Scalars['String'];
  offerDishes: Array<OfferDish>;
  restaurants?: Maybe<Array<Restaurant>>;
  scheduleType: Schedule_Enum;
  status?: Maybe<Offer_Availability_Enum>;
  updatedAt?: Maybe<Scalars['String']>;
  workDays: Array<WorkDay>;
};

export type OfferDish = {
  __typename?: 'OfferDish';
  dish: Dish;
  dishId: Scalars['Int'];
  offerId: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type OfferDishInput = {
  dishId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type OfferOrderAsUserInput = {
  by: Order_Offer_As_User_By_Enum;
  direction: Sort_Direction_Enum;
};

export type OfferOrderInput = {
  by: Order_Offer_By_Enum;
  direction: Sort_Direction_Enum;
};

export type OffersWithCountResponse = {
  __typename?: 'OffersWithCountResponse';
  count: Scalars['Int'];
  rows: Array<Offer>;
};

export type OutscrapperLocation = {
  __typename?: 'OutscrapperLocation';
  name: Scalars['String'];
};

export enum Providers {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
}

export type Query = {
  __typename?: 'Query';
  activeOffers: OffersWithCountResponse;
  advices: Array<Advice>;
  brand: Brand;
  brandEventOffer: EventOffer;
  brandEventOffers: Array<EventOffer>;
  brands: BrandWithCountResponse;
  brandsList: BrandWithCountResponse;
  cities: Array<City>;
  city: City;
  clusteredRestaurantsAsUser: Array<ClusteredRestaurantGroupResponse>;
  countFavorites: Scalars['Int'];
  countries: Array<Country>;
  cuisine: Cuisine;
  cuisines: Array<Cuisine>;
  dish: Dish;
  dishCategories: Array<DishCategory>;
  dishCategory: DishCategory;
  dishes: DishesResponse;
  event: Event;
  eventOffer: EventOffer;
  eventOfferReview: RestaurantReview;
  eventOffers: Array<EventOffer>;
  eventTheme: EventTheme;
  eventThemes: Array<EventTheme>;
  events: EventsWithCountResponse;
  locale: Locale;
  locales: Array<Locale>;
  manager: Manager;
  managerMe: Manager;
  managers: Array<Manager>;
  me: User;
  offer: Offer;
  offerByBrand: Offer;
  offers: OffersWithCountResponse;
  offersByBrand: OffersWithCountResponse;
  outscrapperLocations: Array<OutscrapperLocation>;
  registrationApplication: RegistrationApplication;
  registrationApplications: RegistrationApplicationsResponse;
  restaurant: Restaurant;
  restaurantDishCategories: Array<DishCategory>;
  restaurantReviews: RestaurantReviewWithCountResponse;
  restaurants: RestaurantWithCountResponse;
  restaurantsAsUser: RestaurantWithCountResponse;
  setting: Setting;
  settings: Array<Setting>;
  translate: Translate;
  translates: Array<Translate>;
};

export type QueryActiveOffersArgs = {
  options: GetOffersAsAdminInput;
};

export type QueryBrandArgs = {
  id: Scalars['Int'];
};

export type QueryBrandEventOfferArgs = {
  id: Scalars['Int'];
};

export type QueryBrandEventOffersArgs = {
  options: GetEventOffersInput;
};

export type QueryBrandsArgs = {
  options: GetBrandsInput;
};

export type QueryBrandsListArgs = {
  options: GetBrandsInput;
};

export type QueryCityArgs = {
  id: Scalars['Int'];
};

export type QueryClusteredRestaurantsAsUserArgs = {
  input: GetRestaurantsAsUserInput;
};

export type QueryCuisineArgs = {
  id: Scalars['Int'];
};

export type QueryDishArgs = {
  id: Scalars['Int'];
};

export type QueryDishCategoriesArgs = {
  brandId: Scalars['Int'];
};

export type QueryDishCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryDishesArgs = {
  options: DishesArgs;
};

export type QueryEventArgs = {
  id: Scalars['Int'];
};

export type QueryEventOfferArgs = {
  id: Scalars['Int'];
};

export type QueryEventOfferReviewArgs = {
  options: GetEventOfferReviewInput;
};

export type QueryEventOffersArgs = {
  options: GetEventOffersAsUserInput;
};

export type QueryEventThemeArgs = {
  id: Scalars['Int'];
};

export type QueryEventThemesArgs = {
  cityId?: InputMaybe<Scalars['Int']>;
  day?: InputMaybe<Workday_Enum>;
  timeNumber?: InputMaybe<Scalars['Int']>;
};

export type QueryEventsArgs = {
  options: GetEventsInput;
};

export type QueryLocaleArgs = {
  id: Scalars['Float'];
};

export type QueryLocalesArgs = {
  key?: InputMaybe<Scalars['String']>;
  localeCode?: InputMaybe<Scalars['String']>;
  localeId?: InputMaybe<Scalars['Int']>;
};

export type QueryManagerArgs = {
  id: Scalars['Int'];
};

export type QueryManagersArgs = {
  filter?: InputMaybe<FindFilter>;
};

export type QueryOfferArgs = {
  id: Scalars['Int'];
};

export type QueryOfferByBrandArgs = {
  id: Scalars['Int'];
};

export type QueryOffersArgs = {
  options: GetOffersAsUserInput;
};

export type QueryOffersByBrandArgs = {
  options: GetOffersAsManagerInput;
};

export type QueryOutscrapperLocationsArgs = {
  input: FindOutscrapperLocationsInput;
};

export type QueryRegistrationApplicationArgs = {
  id: Scalars['Int'];
};

export type QueryRegistrationApplicationsArgs = {
  options: RegistrationApplicationsArgs;
};

export type QueryRestaurantArgs = {
  id: Scalars['Int'];
  isSetClicked?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRestaurantDishCategoriesArgs = {
  restaurantId: Scalars['Int'];
};

export type QueryRestaurantReviewsArgs = {
  options: GetRestaurantReviewsInput;
};

export type QueryRestaurantsArgs = {
  getRestaurantsInput: GetRestaurantsInput;
};

export type QueryRestaurantsAsUserArgs = {
  input: GetRestaurantsAsUserInput;
};

export type QuerySettingArgs = {
  key: Scalars['String'];
};

export type QueryTranslateArgs = {
  key: Scalars['String'];
};

export type QueryTranslatesArgs = {
  key?: InputMaybe<Scalars['String']>;
  localeCode?: InputMaybe<Scalars['String']>;
  localeId?: InputMaybe<Scalars['Int']>;
};

export enum Repeat_Frequency_Enum {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type RegisterInput = {
  password: Scalars['String'];
  /** Phone number in format: +111111111111 */
  phone?: InputMaybe<Scalars['String']>;
};

/** registration application */
export type RegistrationApplication = {
  __typename?: 'RegistrationApplication';
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
  restaurantName: Scalars['String'];
};

export type RegistrationApplicationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RegistrationApplicationsResponse = {
  __typename?: 'RegistrationApplicationsResponse';
  count: Scalars['Int'];
  rows: Array<RegistrationApplication>;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  address?: Maybe<Scalars['String']>;
  availableSeatsNumber?: Maybe<Scalars['Int']>;
  averageCost?: Maybe<Scalars['Float']>;
  brandId?: Maybe<Scalars['Int']>;
  city?: Maybe<City>;
  clickCount?: Maybe<Scalars['Int']>;
  closedFrom?: Maybe<Scalars['String']>;
  closedTo?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  cuisines: Array<Cuisine>;
  description?: Maybe<Scalars['String']>;
  dishes: Array<Dish>;
  distance?: Maybe<Scalars['Float']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Image>;
  isClosed: Scalars['Boolean'];
  isFavourite?: Maybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  priceLevel?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  requestCount?: Maybe<Scalars['Int']>;
  scheduleType?: Maybe<Schedule_Enum>;
  secondImage?: Maybe<Image>;
  updatedAt?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Int']>;
  workDays?: Maybe<Array<WorkDay>>;
};

export type RestaurantClickCountArgs = {
  data?: InputMaybe<FromToInput>;
};

export type RestaurantRequestCountArgs = {
  data?: InputMaybe<FromToInput>;
};

export type RestaurantViewCountArgs = {
  data?: InputMaybe<FromToInput>;
};

export type RestaurantReview = {
  __typename?: 'RestaurantReview';
  accurateRating: Scalars['Int'];
  avgRating: Scalars['Float'];
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  cuisineRating: Scalars['Int'];
  eventMatchRating: Scalars['Int'];
  restaurant: Restaurant;
  serviceRating: Scalars['Int'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  user: User;
};

export type RestaurantReviewWithCountResponse = {
  __typename?: 'RestaurantReviewWithCountResponse';
  count: Scalars['Int'];
  rows: Array<RestaurantReview>;
};

export type RestaurantWithCountResponse = {
  __typename?: 'RestaurantWithCountResponse';
  count: Scalars['Int'];
  rows: Array<Restaurant>;
};

export enum Roles {
  Administrator = 'ADMINISTRATOR',
  DishEdit = 'DISH_EDIT',
  DishRemove = 'DISH_REMOVE',
  ManagerEdit = 'MANAGER_EDIT',
  ManagerRemove = 'MANAGER_REMOVE',
  OfferEdit = 'OFFER_EDIT',
  OrderConfirm = 'ORDER_CONFIRM',
  OrderReject = 'ORDER_REJECT',
  RestaurantEdit = 'RESTAURANT_EDIT',
  RestaurantRemove = 'RESTAURANT_REMOVE',
}

export enum Schedule_Enum {
  Flexible = 'FLEXIBLE',
  Static = 'STATIC',
}

export enum Sort_Direction_Enum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SendConfirmationCodeInput = {
  mutation: AllowedMutation;
  phone: Scalars['String'];
};

/** setting */
export type Setting = {
  __typename?: 'Setting';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SignInInput = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type SortInput = {
  direction?: InputMaybe<Sort_Direction_Enum>;
  field?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newEventOffersCount: NewEventOffersCount;
};

export type Translate = {
  __typename?: 'Translate';
  images: Array<TranslateImage>;
  key: Scalars['String'];
  strings: Array<TranslateString>;
  texts: Array<TranslateText>;
};

export type TranslateImage = {
  __typename?: 'TranslateImage';
  image: Image;
  locale: Locale;
};

export type TranslateImageInput = {
  image: Scalars['Upload'];
  localeId?: InputMaybe<Scalars['Int']>;
};

export type TranslateInput = {
  images: Array<TranslateImageInput>;
  key: Scalars['String'];
  strings: Array<TranslateStringInput>;
  texts: Array<TranslateTextInput>;
};

export type TranslateString = {
  __typename?: 'TranslateString';
  locale: Locale;
  string: Scalars['String'];
};

export type TranslateStringInput = {
  localeId?: InputMaybe<Scalars['Int']>;
  string: Scalars['String'];
};

export type TranslateText = {
  __typename?: 'TranslateText';
  locale: Locale;
  text: Scalars['String'];
};

export type TranslateTextInput = {
  localeId?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
};

export type UpdateCityInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  parserCode?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCuisineInput = {
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateDishCategoryInput = {
  brandId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateDishInput = {
  amountPersons?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  calories?: InputMaybe<Scalars['Int']>;
  cookingTime?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  dishCategoryId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  price?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type UpdateEventInput = {
  amountPersons?: InputMaybe<Scalars['Int']>;
  budgetPerPerson?: InputMaybe<Scalars['Float']>;
  centerPointLatitude?: InputMaybe<Scalars['Float']>;
  centerPointLongitude?: InputMaybe<Scalars['Float']>;
  cityId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  cuisineId?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['String']>;
  eventThemeId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['Int']>;
  publishForAll?: InputMaybe<Scalars['Boolean']>;
  radiusInMiles?: InputMaybe<Scalars['Int']>;
  ratingFrom?: InputMaybe<Scalars['Float']>;
  repeatCount?: InputMaybe<Scalars['Int']>;
  repeatOnceAt?: InputMaybe<Repeat_Frequency_Enum>;
  showAfter?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Event_Status_Enum>;
  targetRestaurantId?: InputMaybe<Scalars['Int']>;
  tipPercentage?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Event_Type_Enum>;
};

export type UpdateEventOfferAsUserInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  status: Event_Offer_Status_Enum;
};

export type UpdateEventOfferInput = {
  comment?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  selectedRestaurantId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Event_Offer_Status_Enum>;
  total?: InputMaybe<Scalars['Float']>;
};

export type UpdateEventThemeInput = {
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateLocaleInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateManagerInput = {
  brandId?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<ImageInput>;
  position?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Roles>>;
};

export type UpdateMeInput = {
  cuisineIds?: InputMaybe<Array<Scalars['Int']>>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<ImageInput>;
};

export type UpdateOfferInput = {
  amountPersons?: InputMaybe<Scalars['Int']>;
  budgetPerPerson?: InputMaybe<Scalars['Float']>;
  cuisinesIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  eventThemesIds?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  restaurantsIds?: InputMaybe<Array<Scalars['Int']>>;
  scheduleType?: InputMaybe<Schedule_Enum>;
  status?: InputMaybe<Offer_Availability_Enum>;
  workDays?: InputMaybe<Array<UpdateWorkDayInput>>;
};

export type UpdateOffersIsFavoriteStatusInput = {
  removeFromFavoritesIds?: InputMaybe<Array<Scalars['Int']>>;
  toFavoritesIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdatePhoneInput = {
  /** Phone number in format: +111111111111 */
  phone: Scalars['String'];
};

export type UpdateRestaurantInput = {
  address?: InputMaybe<Scalars['String']>;
  availableSeatsNumber?: InputMaybe<Scalars['Int']>;
  averageCost?: InputMaybe<Scalars['Float']>;
  brandId?: InputMaybe<Scalars['Int']>;
  cityId?: InputMaybe<Scalars['Int']>;
  closedFrom?: InputMaybe<Scalars['String']>;
  closedTo?: InputMaybe<Scalars['String']>;
  cuisinesIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: InputMaybe<Scalars['Upload']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  priceLevel?: InputMaybe<Scalars['Int']>;
  scheduleType?: InputMaybe<Schedule_Enum>;
  secondImage?: InputMaybe<Scalars['Upload']>;
  workDays?: InputMaybe<Array<UpdateWorkDayInput>>;
};

export type UpdateWorkDayInput = {
  day: Workday_Enum;
  from: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
  to: Scalars['Int'];
};

/** user */
export type User = {
  __typename?: 'User';
  cuisines: Array<Cuisine>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Image>;
};

export type VerifyConfirmationCodeInput = {
  code: Scalars['Int'];
  phone: Scalars['String'];
};

export enum Workday_Enum {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type WorkDay = {
  __typename?: 'WorkDay';
  day: Workday_Enum;
  from: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  to: Scalars['Int'];
};

export type AddOrUpdateEventOfferDishesMutationVariables = Exact<{
  input: AddOrUpdateEventOfferDishes;
}>;

export type AddOrUpdateEventOfferDishesMutation = {
  __typename?: 'Mutation';
  addOrUpdateEventOfferDishes: Array<{
    __typename?: 'EventOfferDish';
    quantity: number;
    dishId: number;
    eventOfferId: number;
  }>;
};

export type AddOrUpdateOfferDishesMutationVariables = Exact<{
  input: AddOrUpdateOfferDishInput;
}>;

export type AddOrUpdateOfferDishesMutation = {
  __typename?: 'Mutation';
  addOrUpdateOfferDishes: Array<{ __typename?: 'OfferDish'; quantity: number }>;
};

export type AddTranslateMutationVariables = Exact<{
  translateData: TranslateInput;
}>;

export type AddTranslateMutation = {
  __typename?: 'Mutation';
  addTranslate: {
    __typename?: 'Translate';
    key: string;
    strings: Array<{
      __typename?: 'TranslateString';
      string: string;
      locale: {
        __typename?: 'Locale';
        id: number;
        title: string;
        code: string;
        translates: Array<{ __typename?: 'Translate'; key: string }>;
      };
    }>;
    texts: Array<{
      __typename?: 'TranslateText';
      text: string;
      locale: {
        __typename?: 'Locale';
        code: string;
        id: number;
        title: string;
      };
    }>;
  };
};

export type BulkUpdateAdviceMutationVariables = Exact<{
  advicesData: BulkAdviceInput;
}>;

export type BulkUpdateAdviceMutation = {
  __typename?: 'Mutation';
  bulkUpdateAdvice: Array<{ __typename?: 'Advice'; id: number; text: string }>;
};

export type BulkUpdateCityMutationVariables = Exact<{
  citiesData: BulkCityInput;
}>;

export type BulkUpdateCityMutation = {
  __typename?: 'Mutation';
  bulkUpdateCity: boolean;
};

export type BulkUpdateCuisineMutationVariables = Exact<{
  cuisinesData: BulkCuisineInput;
}>;

export type BulkUpdateCuisineMutation = {
  __typename?: 'Mutation';
  bulkUpdateCuisine: boolean;
};

export type BulkUpdateDishCategoryMutationVariables = Exact<{
  dishCategoriesData: BulkDishCategoryInput;
}>;

export type BulkUpdateDishCategoryMutation = {
  __typename?: 'Mutation';
  bulkUpdateDishCategory: boolean;
};

export type BulkUpdateEventThemeMutationVariables = Exact<{
  eventThemesData: BulkEventThemeInput;
}>;

export type BulkUpdateEventThemeMutation = {
  __typename?: 'Mutation';
  bulkUpdateEventTheme: boolean;
};

export type BulkUpdateSettingMutationVariables = Exact<{
  settingsData: BulkSettingInput;
}>;

export type BulkUpdateSettingMutation = {
  __typename?: 'Mutation';
  bulkUpdateSetting: Array<{
    __typename?: 'Setting';
    key: string;
    value: string;
  }>;
};

export type BulkUpdateTranslateMutationVariables = Exact<{
  translatesData: BulkTranslateInput;
}>;

export type BulkUpdateTranslateMutation = {
  __typename?: 'Mutation';
  bulkUpdateTranslate: Array<{
    __typename?: 'Translate';
    key: string;
    strings: Array<{
      __typename?: 'TranslateString';
      string: string;
      locale: {
        __typename?: 'Locale';
        id: number;
        title: string;
        code: string;
        translates: Array<{ __typename?: 'Translate'; key: string }>;
      };
    }>;
    texts: Array<{ __typename?: 'TranslateText'; text: string }>;
    images: Array<{
      __typename?: 'TranslateImage';
      image: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      };
    }>;
  }>;
};

export type CreateBrandMutationVariables = Exact<{
  createBrandInput: CreateBrandInput;
}>;

export type CreateBrandMutation = {
  __typename?: 'Mutation';
  createBrand: { __typename?: 'Brand'; id: number };
};

export type CreateDishMutationVariables = Exact<{
  createDishInput: CreateDishInput;
}>;

export type CreateDishMutation = {
  __typename?: 'Mutation';
  createDish: { __typename?: 'Dish'; id: number };
};

export type CreateDishCategoryMutationVariables = Exact<{
  createDishCategoryInput: CreateDishCategoryInput;
}>;

export type CreateDishCategoryMutation = {
  __typename?: 'Mutation';
  createDishCategory: { __typename?: 'DishCategory'; id: number };
};

export type CreateManagerMutationVariables = Exact<{
  createManagerInput: CreateManagerInput;
}>;

export type CreateManagerMutation = {
  __typename?: 'Mutation';
  createManager: {
    __typename?: 'Manager';
    id: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position?: string | null;
    roles: Array<Roles>;
    photo?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    brand?: {
      __typename?: 'Brand';
      id: number;
      title: string;
      description: string;
      isActive: boolean;
      restaurantsCount?: number | null;
      image: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      };
    } | null;
  };
};

export type CreateNotificationMutationVariables = Exact<{
  input: CreateNotificationInput;
}>;

export type CreateNotificationMutation = {
  __typename?: 'Mutation';
  createNotification: boolean;
};

export type CreateOfferMutationVariables = Exact<{
  createOfferInput: CreateOfferInput;
}>;

export type CreateOfferMutation = {
  __typename?: 'Mutation';
  createOffer: { __typename?: 'Offer'; id: number };
};

export type CreateRegistrationApplicationMutationVariables = Exact<{
  createRegistrationApplicationInput: CreateRegistrationApplicationInput;
}>;

export type CreateRegistrationApplicationMutation = {
  __typename?: 'Mutation';
  createRegistrationApplication: {
    __typename?: 'RegistrationApplication';
    id: number;
    name: string;
    email: string;
    phone: string;
    restaurantName: string;
    country: string;
    city: string;
    createdAt: string;
  };
};

export type CreateRemindEventOfferNotificationMutationVariables = Exact<{
  eventOfferId: Scalars['Int'];
}>;

export type CreateRemindEventOfferNotificationMutation = {
  __typename?: 'Mutation';
  createRemindEventOfferNotification: boolean;
};

export type CreateRestaurantMutationVariables = Exact<{
  createRestaurantInput: CreateRestaurantInput;
}>;

export type CreateRestaurantMutation = {
  __typename?: 'Mutation';
  createRestaurant: {
    __typename?: 'Restaurant';
    id: number;
    name: string;
    scheduleType?: Schedule_Enum | null;
    priceLevel?: number | null;
    description?: string | null;
    averageCost?: number | null;
    address?: string | null;
    latitude: number;
    longitude: number;
    availableSeatsNumber?: number | null;
    isClosed: boolean;
    createdAt: string;
    updatedAt?: string | null;
    image?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    city?: { __typename?: 'City'; id: number; title: string } | null;
    workDays?: Array<{
      __typename?: 'WorkDay';
      id: number;
      day: Workday_Enum;
      from: number;
      to: number;
      isActive: boolean;
    }> | null;
    secondImage?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
  };
};

export type DishesInRestaurantMutationVariables = Exact<{
  dishesInRestaurantInput: DishesInRestaurantInput;
}>;

export type DishesInRestaurantMutation = {
  __typename?: 'Mutation';
  dishesInRestaurant: boolean;
};

export type ManagerRestorePasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ManagerRestorePasswordMutation = {
  __typename?: 'Mutation';
  managerRestorePassword: boolean;
};

export type OffersToFavoritesMutationVariables = Exact<{
  updates: UpdateOffersIsFavoriteStatusInput;
}>;

export type OffersToFavoritesMutation = {
  __typename?: 'Mutation';
  offersToFavorites: boolean;
};

export type ParseCityMutationVariables = Exact<{
  cityId: Scalars['Int'];
}>;

export type ParseCityMutation = {
  __typename?: 'Mutation';
  parseCity: { __typename?: 'City'; id: number };
};

export type RemoveBrandMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveBrandMutation = {
  __typename?: 'Mutation';
  removeBrand: boolean;
};

export type RemoveDishMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveDishMutation = {
  __typename?: 'Mutation';
  removeDish: boolean;
};

export type RemoveManagerMutationVariables = Exact<{
  removeManagerId: Scalars['Int'];
}>;

export type RemoveManagerMutation = {
  __typename?: 'Mutation';
  removeManager: { __typename?: 'Manager'; id: number };
};

export type RemoveTranslateMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type RemoveTranslateMutation = {
  __typename?: 'Mutation';
  removeTranslate: boolean;
};

export type ManagerSignInMutationVariables = Exact<{
  managerSignInInput: ManagerSignInInput;
}>;

export type ManagerSignInMutation = {
  __typename?: 'Mutation';
  managerSignIn: {
    __typename?: 'ManagerAuth';
    accessToken: string;
    manager: {
      __typename?: 'Manager';
      id: number;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position?: string | null;
      roles: Array<Roles>;
      photo?: {
        __typename?: 'Image';
        width: number;
        url: string;
        size: number;
        mime: string;
        id: number;
        height: number;
      } | null;
      brand?: { __typename?: 'Brand'; id: number } | null;
    };
  };
};

export type SignInAsManagerMutationVariables = Exact<{
  signInAsManagerId: Scalars['Int'];
}>;

export type SignInAsManagerMutation = {
  __typename?: 'Mutation';
  signInAsManager: {
    __typename?: 'ManagerAuth';
    accessToken: string;
    manager: {
      __typename?: 'Manager';
      id: number;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position?: string | null;
      roles: Array<Roles>;
      photo?: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      } | null;
      brand?: { __typename?: 'Brand'; id: number } | null;
    };
  };
};

export type UpdateBrandMutationVariables = Exact<{
  input: EditBrandInput;
}>;

export type UpdateBrandMutation = {
  __typename?: 'Mutation';
  updateBrand: { __typename?: 'Brand'; id: number };
};

export type UpdateDishMutationVariables = Exact<{
  updateDishInput: UpdateDishInput;
}>;

export type UpdateDishMutation = {
  __typename?: 'Mutation';
  updateDish: { __typename?: 'Dish'; id: number };
};

export type UpdateEventOfferMutationVariables = Exact<{
  updates: UpdateEventOfferInput;
}>;

export type UpdateEventOfferMutation = {
  __typename?: 'Mutation';
  updateEventOffer: { __typename?: 'EventOffer'; id: number };
};

export type UpdateManagerMutationVariables = Exact<{
  updateManagerInput: UpdateManagerInput;
}>;

export type UpdateManagerMutation = {
  __typename?: 'Mutation';
  updateManager: {
    __typename?: 'Manager';
    id: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position?: string | null;
    roles: Array<Roles>;
    photo?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
  };
};

export type UpdateOfferMutationVariables = Exact<{
  updateOfferInput: UpdateOfferInput;
}>;

export type UpdateOfferMutation = {
  __typename?: 'Mutation';
  updateOffer: {
    __typename?: 'Offer';
    id: number;
    name: string;
    description: string;
    amountPersons: number;
    budgetPerPerson: number;
    status?: Offer_Availability_Enum | null;
    isFavorite: boolean;
    createdAt: string;
    updatedAt?: string | null;
    brand: {
      __typename?: 'Brand';
      id: number;
      title: string;
      description: string;
      isActive: boolean;
      restaurantsCount?: number | null;
    };
    image?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    workDays: Array<{
      __typename?: 'WorkDay';
      id: number;
      day: Workday_Enum;
      from: number;
      to: number;
      isActive: boolean;
    }>;
    cuisines: Array<{ __typename?: 'Cuisine'; id: number; title: string }>;
    eventThemes: Array<{
      __typename?: 'EventTheme';
      id: number;
      title: string;
      isFavorite: boolean;
    }>;
    offerDishes: Array<{
      __typename?: 'OfferDish';
      quantity: number;
      dishId: number;
      offerId: number;
      dish: {
        __typename?: 'Dish';
        id: number;
        title: string;
        description: string;
        brandId: number;
        dishCategoryId: number;
        price: number;
        cookingTime?: number | null;
        amountPersons?: number | null;
        calories?: number | null;
        weight?: number | null;
        volume?: number | null;
      };
    }>;
  };
};

export type UpdateRestaurantMutationVariables = Exact<{
  updateRestaurantInput: UpdateRestaurantInput;
}>;

export type UpdateRestaurantMutation = {
  __typename?: 'Mutation';
  updateRestaurant: {
    __typename?: 'Restaurant';
    id: number;
    name: string;
    scheduleType?: Schedule_Enum | null;
    priceLevel?: number | null;
    description?: string | null;
    averageCost?: number | null;
    address?: string | null;
    latitude: number;
    longitude: number;
    availableSeatsNumber?: number | null;
    isClosed: boolean;
    createdAt: string;
    updatedAt?: string | null;
    image?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    city?: { __typename?: 'City'; id: number; title: string } | null;
    workDays?: Array<{
      __typename?: 'WorkDay';
      id: number;
      day: Workday_Enum;
      from: number;
      to: number;
      isActive: boolean;
    }> | null;
    secondImage?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
  };
};

export type ActiveOffersQueryVariables = Exact<{
  options: GetOffersAsAdminInput;
}>;

export type ActiveOffersQuery = {
  __typename?: 'Query';
  activeOffers: {
    __typename?: 'OffersWithCountResponse';
    count: number;
    rows: Array<{
      __typename?: 'Offer';
      id: number;
      name: string;
      description: string;
      amountPersons: number;
      budgetPerPerson: number;
      status?: Offer_Availability_Enum | null;
      isFavorite: boolean;
      createdAt: string;
      updatedAt?: string | null;
      brand: {
        __typename?: 'Brand';
        id: number;
        title: string;
        description: string;
        isActive: boolean;
        restaurantsCount?: number | null;
      };
      image?: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      } | null;
      restaurants?: Array<{
        __typename?: 'Restaurant';
        id: number;
        name: string;
        scheduleType?: Schedule_Enum | null;
        priceLevel?: number | null;
        description?: string | null;
        averageCost?: number | null;
        address?: string | null;
        latitude: number;
        longitude: number;
        availableSeatsNumber?: number | null;
        isClosed: boolean;
        brandId?: number | null;
        createdAt: string;
        updatedAt?: string | null;
        closedFrom?: string | null;
        closedTo?: string | null;
        city?: {
          __typename?: 'City';
          id: number;
          title: string;
          latitude: number;
          longitude: number;
        } | null;
        image?: {
          __typename?: 'Image';
          id: number;
          width: number;
          height: number;
          mime: string;
          size: number;
          url: string;
        } | null;
      }> | null;
      cuisines: Array<{ __typename?: 'Cuisine'; id: number; title: string }>;
      eventThemes: Array<{
        __typename?: 'EventTheme';
        id: number;
        title: string;
        isFavorite: boolean;
      }>;
      offerDishes: Array<{
        __typename?: 'OfferDish';
        quantity: number;
        dishId: number;
        offerId: number;
        dish: {
          __typename?: 'Dish';
          id: number;
          title: string;
          description: string;
          brandId: number;
          price: number;
          cookingTime?: number | null;
          amountPersons?: number | null;
          calories?: number | null;
          weight?: number | null;
          volume?: number | null;
        };
      }>;
    }>;
  };
};

export type AdvicesQueryVariables = Exact<{ [key: string]: never }>;

export type AdvicesQuery = {
  __typename?: 'Query';
  advices: Array<{ __typename?: 'Advice'; id: number; text: string }>;
};

export type BrandQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type BrandQuery = {
  __typename?: 'Query';
  brand: {
    __typename?: 'Brand';
    id: number;
    title: string;
    description: string;
    isActive: boolean;
    restaurantsCount?: number | null;
    image: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    };
  };
};

export type BrandEventOfferQueryVariables = Exact<{
  brandEventOfferId: Scalars['Int'];
}>;

export type BrandEventOfferQuery = {
  __typename?: 'Query';
  brandEventOffer: {
    __typename?: 'EventOffer';
    id: number;
    suitableRestaurantsIds: Array<number>;
    brandId: number;
    event: {
      __typename?: 'Event';
      id: number;
      startAt: string;
      name: string;
      budgetPerPerson: number;
      amountPersons: number;
      tipPercentage?: number | null;
      endAt?: string | null;
    };
    eventOfferDishes?: Array<{
      __typename?: 'EventOfferDish';
      quantity: number;
      eventOfferId: number;
      dishId: number;
      dish: {
        __typename?: 'Dish';
        price: number;
        amountPersons?: number | null;
        title: string;
        image: { __typename?: 'Image'; url: string };
      };
    }> | null;
  };
};

export type BrandEventOffersQueryVariables = Exact<{
  options: GetEventOffersInput;
}>;

export type BrandEventOffersQuery = {
  __typename?: 'Query';
  brandEventOffers: Array<{
    __typename?: 'EventOffer';
    id: number;
    comment?: string | null;
    selectedRestaurantId?: number | null;
    status: Event_Offer_Status_Enum;
    suitableRestaurantsIds: Array<number>;
    total?: number | null;
    discount?: number | null;
    event: {
      __typename?: 'Event';
      name: string;
      ratingFrom?: number | null;
      budgetPerPerson: number;
      amountPersons: number;
      repeatOnceAt?: Repeat_Frequency_Enum | null;
      startAt: string;
      endAt?: string | null;
      updatedAt?: string | null;
      eventTheme: {
        __typename?: 'EventTheme';
        id: number;
        title: string;
        isFavorite: boolean;
      };
      user: { __typename?: 'User'; id: number };
    };
    eventOfferDishes?: Array<{
      __typename?: 'EventOfferDish';
      quantity: number;
      eventOfferId: number;
      dishId: number;
      dish: {
        __typename?: 'Dish';
        price: number;
        title: string;
        id: number;
        description: string;
        image: { __typename?: 'Image'; url: string };
      };
    }> | null;
    restaurantReview?: {
      __typename?: 'RestaurantReview';
      accurateRating: number;
      eventMatchRating: number;
      cuisineRating: number;
      serviceRating: number;
      avgRating: number;
      comment?: string | null;
      createdAt: string;
      user: {
        __typename?: 'User';
        name?: string | null;
        photo?: { __typename?: 'Image'; url: string } | null;
      };
    } | null;
  }>;
};

export type BrandsQueryVariables = Exact<{
  options: GetBrandsInput;
}>;

export type BrandsQuery = {
  __typename?: 'Query';
  brands: {
    __typename?: 'BrandWithCountResponse';
    count: number;
    rows: Array<{
      __typename?: 'Brand';
      id: number;
      title: string;
      description: string;
      isActive: boolean;
      restaurantsCount?: number | null;
      image: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      };
    }>;
  };
};

export type CitiesQueryVariables = Exact<{ [key: string]: never }>;

export type CitiesQuery = {
  __typename?: 'Query';
  cities: Array<{
    __typename?: 'City';
    id: number;
    title: string;
    latitude: number;
    longitude: number;
    parserLastParsedDate?: string | null;
    parserCode?: string | null;
    parserStatus?: string | null;
    countryCode?: string | null;
  }>;
};

export type CountFavoritesQueryVariables = Exact<{ [key: string]: never }>;

export type CountFavoritesQuery = {
  __typename?: 'Query';
  countFavorites: number;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: 'Query';
  countries: Array<{ __typename?: 'Country'; code: string; name: string }>;
};

export type CuisinesQueryVariables = Exact<{ [key: string]: never }>;

export type CuisinesQuery = {
  __typename?: 'Query';
  cuisines: Array<{
    __typename?: 'Cuisine';
    id: number;
    title: string;
    image: { __typename?: 'Image'; url: string };
  }>;
};

export type DishQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DishQuery = {
  __typename?: 'Query';
  dish: {
    __typename?: 'Dish';
    id: number;
    title: string;
    description: string;
    brandId: number;
    dishCategoryId: number;
    price: number;
    cookingTime?: number | null;
    amountPersons?: number | null;
    calories?: number | null;
    weight?: number | null;
    volume?: number | null;
    image: { __typename?: 'Image'; url: string };
  };
};

export type DishCategoriesQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type DishCategoriesQuery = {
  __typename?: 'Query';
  dishCategories: Array<{
    __typename?: 'DishCategory';
    id: number;
    title: string;
    countDishes?: number | null;
    brandId: number;
  }>;
};

export type DishesQueryVariables = Exact<{
  options: DishesArgs;
}>;

export type DishesQuery = {
  __typename?: 'Query';
  dishes: {
    __typename?: 'DishesResponse';
    count: number;
    rows: Array<{
      __typename?: 'Dish';
      id: number;
      title: string;
      description: string;
      brandId: number;
      dishCategoryId: number;
      price: number;
      cookingTime?: number | null;
      amountPersons?: number | null;
      calories?: number | null;
      weight?: number | null;
      volume?: number | null;
      dishCategory: { __typename?: 'DishCategory'; id: number; title: string };
      image: { __typename?: 'Image'; url: string };
    }>;
  };
};

export type EventOfferReviewQueryVariables = Exact<{
  options: GetEventOfferReviewInput;
}>;

export type EventOfferReviewQuery = {
  __typename?: 'Query';
  eventOfferReview: {
    __typename?: 'RestaurantReview';
    accurateRating: number;
    eventMatchRating: number;
    cuisineRating: number;
    serviceRating: number;
    avgRating: number;
    comment?: string | null;
    createdAt: string;
    user: {
      __typename?: 'User';
      name?: string | null;
      photo?: { __typename?: 'Image'; url: string } | null;
    };
  };
};

export type EventThemesQueryVariables = Exact<{ [key: string]: never }>;

export type EventThemesQuery = {
  __typename?: 'Query';
  eventThemes: Array<{
    __typename?: 'EventTheme';
    id: number;
    title: string;
    isFavorite: boolean;
    image: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    };
  }>;
};

export type ManagerQueryVariables = Exact<{
  managerId: Scalars['Int'];
}>;

export type ManagerQuery = {
  __typename?: 'Query';
  manager: {
    __typename?: 'Manager';
    id: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position?: string | null;
    roles: Array<Roles>;
    photo?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    brand?: { __typename?: 'Brand'; id: number } | null;
  };
};

export type ManagersQueryVariables = Exact<{
  filter?: InputMaybe<FindFilter>;
}>;

export type ManagersQuery = {
  __typename?: 'Query';
  managers: Array<{
    __typename?: 'Manager';
    id: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position?: string | null;
    roles: Array<Roles>;
    photo?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    brand?: { __typename?: 'Brand'; id: number; isActive: boolean } | null;
  }>;
};

export type OfferQueryVariables = Exact<{
  offerId: Scalars['Int'];
}>;

export type OfferQuery = {
  __typename?: 'Query';
  offer: {
    __typename?: 'Offer';
    id: number;
    name: string;
    description: string;
    scheduleType: Schedule_Enum;
    amountPersons: number;
    budgetPerPerson: number;
    eventThemes: Array<{
      __typename?: 'EventTheme';
      id: number;
      title: string;
    }>;
    workDays: Array<{
      __typename?: 'WorkDay';
      id: number;
      day: Workday_Enum;
      from: number;
      to: number;
      isActive: boolean;
    }>;
    cuisines: Array<{ __typename?: 'Cuisine'; title: string; id: number }>;
    brand: { __typename?: 'Brand'; id: number };
    image?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    restaurants?: Array<{
      __typename?: 'Restaurant';
      id: number;
      name: string;
    }> | null;
    offerDishes: Array<{
      __typename?: 'OfferDish';
      quantity: number;
      dishId: number;
      offerId: number;
      dish: {
        __typename?: 'Dish';
        id: number;
        title: string;
        description: string;
        brandId: number;
        price: number;
        cookingTime?: number | null;
        amountPersons?: number | null;
        calories?: number | null;
        weight?: number | null;
        volume?: number | null;
        image: {
          __typename?: 'Image';
          id: number;
          width: number;
          height: number;
          mime: string;
          size: number;
          url: string;
        };
      };
    }>;
  };
};

export type OfferByBrandQueryVariables = Exact<{
  offerByBrandId: Scalars['Int'];
}>;

export type OfferByBrandQuery = {
  __typename?: 'Query';
  offerByBrand: {
    __typename?: 'Offer';
    id: number;
    name: string;
    description: string;
    scheduleType: Schedule_Enum;
    budgetPerPerson: number;
    cuisines: Array<{ __typename?: 'Cuisine'; title: string; id: number }>;
    image?: { __typename?: 'Image'; url: string } | null;
    offerDishes: Array<{
      __typename?: 'OfferDish';
      quantity: number;
      offerId: number;
      dishId: number;
      dish: {
        __typename?: 'Dish';
        calories?: number | null;
        description: string;
        id: number;
        price: number;
        title: string;
        volume?: number | null;
        weight?: number | null;
        amountPersons?: number | null;
        cookingTime?: number | null;
        image: { __typename?: 'Image'; url: string };
      };
    }>;
    workDays: Array<{
      __typename?: 'WorkDay';
      day: Workday_Enum;
      from: number;
      id: number;
      isActive: boolean;
      to: number;
    }>;
  };
};

export type OffersByBrandQueryVariables = Exact<{
  options: GetOffersAsManagerInput;
}>;

export type OffersByBrandQuery = {
  __typename?: 'Query';
  offersByBrand: {
    __typename?: 'OffersWithCountResponse';
    count: number;
    rows: Array<{
      __typename?: 'Offer';
      scheduleType: Schedule_Enum;
      id: number;
      name: string;
      budgetPerPerson: number;
      workDays: Array<{
        __typename?: 'WorkDay';
        id: number;
        day: Workday_Enum;
        from: number;
        to: number;
        isActive: boolean;
      }>;
      restaurants?: Array<{
        __typename?: 'Restaurant';
        address?: string | null;
      }> | null;
    }>;
  };
};

export type RegistrationApplicationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RegistrationApplicationQuery = {
  __typename?: 'Query';
  registrationApplication: {
    __typename?: 'RegistrationApplication';
    id: number;
    name: string;
    email: string;
    phone: string;
    restaurantName: string;
    country: string;
    city: string;
    createdAt: string;
  };
};

export type RegistrationApplicationsQueryVariables = Exact<{
  options: RegistrationApplicationsArgs;
}>;

export type RegistrationApplicationsQuery = {
  __typename?: 'Query';
  registrationApplications: {
    __typename?: 'RegistrationApplicationsResponse';
    count: number;
    rows: Array<{
      __typename?: 'RegistrationApplication';
      id: number;
      name: string;
      email: string;
      phone: string;
      restaurantName: string;
      country: string;
      city: string;
      createdAt: string;
    }>;
  };
};

export type RemoveRestaurantMutationVariables = Exact<{
  removeRestaurantId: Scalars['Int'];
}>;

export type RemoveRestaurantMutation = {
  __typename?: 'Mutation';
  removeRestaurant: boolean;
};

export type RestaurantQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RestaurantQuery = {
  __typename?: 'Query';
  restaurant: {
    __typename?: 'Restaurant';
    id: number;
    brandId?: number | null;
    name: string;
    scheduleType?: Schedule_Enum | null;
    priceLevel?: number | null;
    description?: string | null;
    averageCost?: number | null;
    address?: string | null;
    latitude: number;
    longitude: number;
    availableSeatsNumber?: number | null;
    isClosed: boolean;
    closedFrom?: string | null;
    closedTo?: string | null;
    createdAt: string;
    updatedAt?: string | null;
    image?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    city?: { __typename?: 'City'; id: number; title: string } | null;
    cuisines: Array<{ __typename?: 'Cuisine'; id: number; title: string }>;
    workDays?: Array<{
      __typename?: 'WorkDay';
      id: number;
      day: Workday_Enum;
      from: number;
      to: number;
      isActive: boolean;
    }> | null;
    dishes: Array<{ __typename?: 'Dish'; id: number }>;
    secondImage?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
  };
};

export type RestaurantDetailWithoutBrandPageQueryVariables = Exact<{
  id: Scalars['Int'];
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
}>;

export type RestaurantDetailWithoutBrandPageQuery = {
  __typename?: 'Query';
  restaurant: {
    __typename?: 'Restaurant';
    id: number;
    brandId?: number | null;
    name: string;
    scheduleType?: Schedule_Enum | null;
    priceLevel?: number | null;
    description?: string | null;
    averageCost?: number | null;
    address?: string | null;
    latitude: number;
    longitude: number;
    availableSeatsNumber?: number | null;
    isClosed: boolean;
    closedFrom?: string | null;
    closedTo?: string | null;
    createdAt: string;
    updatedAt?: string | null;
    viewCount?: number | null;
    clickCount?: number | null;
    requestCount?: number | null;
    image?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
    city?: { __typename?: 'City'; id: number; title: string } | null;
    cuisines: Array<{ __typename?: 'Cuisine'; id: number; title: string }>;
    workDays?: Array<{
      __typename?: 'WorkDay';
      id: number;
      day: Workday_Enum;
      from: number;
      to: number;
      isActive: boolean;
    }> | null;
    dishes: Array<{ __typename?: 'Dish'; id: number }>;
    secondImage?: {
      __typename?: 'Image';
      id: number;
      width: number;
      height: number;
      mime: string;
      size: number;
      url: string;
    } | null;
  };
};

export type RestaurantsQueryVariables = Exact<{
  getRestaurantsInput: GetRestaurantsInput;
}>;

export type RestaurantsQuery = {
  __typename?: 'Query';
  restaurants: {
    __typename?: 'RestaurantWithCountResponse';
    count: number;
    rows: Array<{
      __typename?: 'Restaurant';
      id: number;
      brandId?: number | null;
      name: string;
      scheduleType?: Schedule_Enum | null;
      priceLevel?: number | null;
      description?: string | null;
      averageCost?: number | null;
      address?: string | null;
      latitude: number;
      longitude: number;
      availableSeatsNumber?: number | null;
      isClosed: boolean;
      createdAt: string;
      updatedAt?: string | null;
      image?: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      } | null;
      city?: { __typename?: 'City'; id: number; title: string } | null;
      cuisines: Array<{ __typename?: 'Cuisine'; id: number; title: string }>;
      workDays?: Array<{
        __typename?: 'WorkDay';
        id: number;
        day: Workday_Enum;
        from: number;
        to: number;
        isActive: boolean;
      }> | null;
    }>;
  };
};

export type RestaurantWithoutBrandPageQueryVariables = Exact<{
  getRestaurantsInput: GetRestaurantsInput;
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
}>;

export type RestaurantWithoutBrandPageQuery = {
  __typename?: 'Query';
  restaurants: {
    __typename?: 'RestaurantWithCountResponse';
    count: number;
    rows: Array<{
      __typename?: 'Restaurant';
      id: number;
      brandId?: number | null;
      name: string;
      scheduleType?: Schedule_Enum | null;
      priceLevel?: number | null;
      description?: string | null;
      averageCost?: number | null;
      address?: string | null;
      latitude: number;
      longitude: number;
      availableSeatsNumber?: number | null;
      isClosed: boolean;
      createdAt: string;
      updatedAt?: string | null;
      viewCount?: number | null;
      clickCount?: number | null;
      requestCount?: number | null;
      image?: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      } | null;
      city?: { __typename?: 'City'; id: number; title: string } | null;
      cuisines: Array<{ __typename?: 'Cuisine'; id: number; title: string }>;
      workDays?: Array<{
        __typename?: 'WorkDay';
        id: number;
        day: Workday_Enum;
        from: number;
        to: number;
        isActive: boolean;
      }> | null;
    }>;
  };
};

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = {
  __typename?: 'Query';
  settings: Array<{ __typename?: 'Setting'; key: string; value: string }>;
};

export type SwitchRestaurantClosedStatusMutationVariables = Exact<{
  switchRestaurantClosedStatusId: Scalars['Int'];
}>;

export type SwitchRestaurantClosedStatusMutation = {
  __typename?: 'Mutation';
  switchRestaurantClosedStatus: {
    __typename?: 'Restaurant';
    id: number;
    isClosed: boolean;
  };
};

export type TranslatesQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']>;
  localeId?: InputMaybe<Scalars['Int']>;
  localeCode?: InputMaybe<Scalars['String']>;
}>;

export type TranslatesQuery = {
  __typename?: 'Query';
  translates: Array<{
    __typename?: 'Translate';
    key: string;
    strings: Array<{
      __typename?: 'TranslateString';
      string: string;
      locale: {
        __typename?: 'Locale';
        id: number;
        title: string;
        code: string;
      };
    }>;
    texts: Array<{
      __typename?: 'TranslateText';
      text: string;
      locale: {
        __typename?: 'Locale';
        code: string;
        id: number;
        title: string;
      };
    }>;
    images: Array<{
      __typename?: 'TranslateImage';
      image: {
        __typename?: 'Image';
        id: number;
        width: number;
        height: number;
        mime: string;
        size: number;
        url: string;
      };
      locale: {
        __typename?: 'Locale';
        code: string;
        id: number;
        title: string;
      };
    }>;
  }>;
};

export type ManagerMeQueryVariables = Exact<{ [key: string]: never }>;

export type ManagerMeQuery = {
  __typename?: 'Query';
  managerMe: {
    __typename?: 'Manager';
    id: number;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position?: string | null;
    roles: Array<Roles>;
    photo?: { __typename?: 'Image'; url: string } | null;
    brand?: { __typename?: 'Brand'; id: number } | null;
  };
};

export type NewEventOffersCountSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NewEventOffersCountSubscription = {
  __typename?: 'Subscription';
  newEventOffersCount: { __typename?: 'NewEventOffersCount'; count: number };
};

export type OutscrapperLocationsQueryVariables = Exact<{
  input: FindOutscrapperLocationsInput;
}>;

export type OutscrapperLocationsQuery = {
  __typename?: 'Query';
  outscrapperLocations: Array<{
    __typename?: 'OutscrapperLocation';
    name: string;
  }>;
};

export const AddOrUpdateEventOfferDishesDocument = gql`
  mutation AddOrUpdateEventOfferDishes($input: AddOrUpdateEventOfferDishes!) {
    addOrUpdateEventOfferDishes(input: $input) {
      quantity
      dishId
      eventOfferId
    }
  }
`;
export type AddOrUpdateEventOfferDishesMutationFn = Apollo.MutationFunction<
  AddOrUpdateEventOfferDishesMutation,
  AddOrUpdateEventOfferDishesMutationVariables
>;

/**
 * __useAddOrUpdateEventOfferDishesMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateEventOfferDishesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateEventOfferDishesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateEventOfferDishesMutation, { data, loading, error }] = useAddOrUpdateEventOfferDishesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrUpdateEventOfferDishesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrUpdateEventOfferDishesMutation,
    AddOrUpdateEventOfferDishesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddOrUpdateEventOfferDishesMutation,
    AddOrUpdateEventOfferDishesMutationVariables
  >(AddOrUpdateEventOfferDishesDocument, options);
}
export type AddOrUpdateEventOfferDishesMutationHookResult = ReturnType<
  typeof useAddOrUpdateEventOfferDishesMutation
>;
export type AddOrUpdateEventOfferDishesMutationResult =
  Apollo.MutationResult<AddOrUpdateEventOfferDishesMutation>;
export type AddOrUpdateEventOfferDishesMutationOptions =
  Apollo.BaseMutationOptions<
    AddOrUpdateEventOfferDishesMutation,
    AddOrUpdateEventOfferDishesMutationVariables
  >;
export const AddOrUpdateOfferDishesDocument = gql`
  mutation addOrUpdateOfferDishes($input: AddOrUpdateOfferDishInput!) {
    addOrUpdateOfferDishes(input: $input) {
      quantity
    }
  }
`;
export type AddOrUpdateOfferDishesMutationFn = Apollo.MutationFunction<
  AddOrUpdateOfferDishesMutation,
  AddOrUpdateOfferDishesMutationVariables
>;

/**
 * __useAddOrUpdateOfferDishesMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateOfferDishesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateOfferDishesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateOfferDishesMutation, { data, loading, error }] = useAddOrUpdateOfferDishesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrUpdateOfferDishesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrUpdateOfferDishesMutation,
    AddOrUpdateOfferDishesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddOrUpdateOfferDishesMutation,
    AddOrUpdateOfferDishesMutationVariables
  >(AddOrUpdateOfferDishesDocument, options);
}
export type AddOrUpdateOfferDishesMutationHookResult = ReturnType<
  typeof useAddOrUpdateOfferDishesMutation
>;
export type AddOrUpdateOfferDishesMutationResult =
  Apollo.MutationResult<AddOrUpdateOfferDishesMutation>;
export type AddOrUpdateOfferDishesMutationOptions = Apollo.BaseMutationOptions<
  AddOrUpdateOfferDishesMutation,
  AddOrUpdateOfferDishesMutationVariables
>;
export const AddTranslateDocument = gql`
  mutation AddTranslate($translateData: TranslateInput!) {
    addTranslate(translateData: $translateData) {
      key
      strings {
        locale {
          id
          title
          code
          translates {
            key
          }
        }
        string
      }
      texts {
        text
        locale {
          code
          id
          title
        }
      }
    }
  }
`;
export type AddTranslateMutationFn = Apollo.MutationFunction<
  AddTranslateMutation,
  AddTranslateMutationVariables
>;

/**
 * __useAddTranslateMutation__
 *
 * To run a mutation, you first call `useAddTranslateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTranslateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTranslateMutation, { data, loading, error }] = useAddTranslateMutation({
 *   variables: {
 *      translateData: // value for 'translateData'
 *   },
 * });
 */
export function useAddTranslateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTranslateMutation,
    AddTranslateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTranslateMutation,
    AddTranslateMutationVariables
  >(AddTranslateDocument, options);
}
export type AddTranslateMutationHookResult = ReturnType<
  typeof useAddTranslateMutation
>;
export type AddTranslateMutationResult =
  Apollo.MutationResult<AddTranslateMutation>;
export type AddTranslateMutationOptions = Apollo.BaseMutationOptions<
  AddTranslateMutation,
  AddTranslateMutationVariables
>;
export const BulkUpdateAdviceDocument = gql`
  mutation BulkUpdateAdvice($advicesData: BulkAdviceInput!) {
    bulkUpdateAdvice(advicesData: $advicesData) {
      id
      text
    }
  }
`;
export type BulkUpdateAdviceMutationFn = Apollo.MutationFunction<
  BulkUpdateAdviceMutation,
  BulkUpdateAdviceMutationVariables
>;

/**
 * __useBulkUpdateAdviceMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAdviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAdviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAdviceMutation, { data, loading, error }] = useBulkUpdateAdviceMutation({
 *   variables: {
 *      advicesData: // value for 'advicesData'
 *   },
 * });
 */
export function useBulkUpdateAdviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateAdviceMutation,
    BulkUpdateAdviceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateAdviceMutation,
    BulkUpdateAdviceMutationVariables
  >(BulkUpdateAdviceDocument, options);
}
export type BulkUpdateAdviceMutationHookResult = ReturnType<
  typeof useBulkUpdateAdviceMutation
>;
export type BulkUpdateAdviceMutationResult =
  Apollo.MutationResult<BulkUpdateAdviceMutation>;
export type BulkUpdateAdviceMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateAdviceMutation,
  BulkUpdateAdviceMutationVariables
>;
export const BulkUpdateCityDocument = gql`
  mutation BulkUpdateCity($citiesData: BulkCityInput!) {
    bulkUpdateCity(citiesData: $citiesData)
  }
`;
export type BulkUpdateCityMutationFn = Apollo.MutationFunction<
  BulkUpdateCityMutation,
  BulkUpdateCityMutationVariables
>;

/**
 * __useBulkUpdateCityMutation__
 *
 * To run a mutation, you first call `useBulkUpdateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateCityMutation, { data, loading, error }] = useBulkUpdateCityMutation({
 *   variables: {
 *      citiesData: // value for 'citiesData'
 *   },
 * });
 */
export function useBulkUpdateCityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateCityMutation,
    BulkUpdateCityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateCityMutation,
    BulkUpdateCityMutationVariables
  >(BulkUpdateCityDocument, options);
}
export type BulkUpdateCityMutationHookResult = ReturnType<
  typeof useBulkUpdateCityMutation
>;
export type BulkUpdateCityMutationResult =
  Apollo.MutationResult<BulkUpdateCityMutation>;
export type BulkUpdateCityMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateCityMutation,
  BulkUpdateCityMutationVariables
>;
export const BulkUpdateCuisineDocument = gql`
  mutation BulkUpdateCuisine($cuisinesData: BulkCuisineInput!) {
    bulkUpdateCuisine(cuisinesData: $cuisinesData)
  }
`;
export type BulkUpdateCuisineMutationFn = Apollo.MutationFunction<
  BulkUpdateCuisineMutation,
  BulkUpdateCuisineMutationVariables
>;

/**
 * __useBulkUpdateCuisineMutation__
 *
 * To run a mutation, you first call `useBulkUpdateCuisineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateCuisineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateCuisineMutation, { data, loading, error }] = useBulkUpdateCuisineMutation({
 *   variables: {
 *      cuisinesData: // value for 'cuisinesData'
 *   },
 * });
 */
export function useBulkUpdateCuisineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateCuisineMutation,
    BulkUpdateCuisineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateCuisineMutation,
    BulkUpdateCuisineMutationVariables
  >(BulkUpdateCuisineDocument, options);
}
export type BulkUpdateCuisineMutationHookResult = ReturnType<
  typeof useBulkUpdateCuisineMutation
>;
export type BulkUpdateCuisineMutationResult =
  Apollo.MutationResult<BulkUpdateCuisineMutation>;
export type BulkUpdateCuisineMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateCuisineMutation,
  BulkUpdateCuisineMutationVariables
>;
export const BulkUpdateDishCategoryDocument = gql`
  mutation bulkUpdateDishCategory($dishCategoriesData: BulkDishCategoryInput!) {
    bulkUpdateDishCategory(dishCategoriesData: $dishCategoriesData)
  }
`;
export type BulkUpdateDishCategoryMutationFn = Apollo.MutationFunction<
  BulkUpdateDishCategoryMutation,
  BulkUpdateDishCategoryMutationVariables
>;

/**
 * __useBulkUpdateDishCategoryMutation__
 *
 * To run a mutation, you first call `useBulkUpdateDishCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateDishCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateDishCategoryMutation, { data, loading, error }] = useBulkUpdateDishCategoryMutation({
 *   variables: {
 *      dishCategoriesData: // value for 'dishCategoriesData'
 *   },
 * });
 */
export function useBulkUpdateDishCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateDishCategoryMutation,
    BulkUpdateDishCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateDishCategoryMutation,
    BulkUpdateDishCategoryMutationVariables
  >(BulkUpdateDishCategoryDocument, options);
}
export type BulkUpdateDishCategoryMutationHookResult = ReturnType<
  typeof useBulkUpdateDishCategoryMutation
>;
export type BulkUpdateDishCategoryMutationResult =
  Apollo.MutationResult<BulkUpdateDishCategoryMutation>;
export type BulkUpdateDishCategoryMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateDishCategoryMutation,
  BulkUpdateDishCategoryMutationVariables
>;
export const BulkUpdateEventThemeDocument = gql`
  mutation BulkUpdateEventTheme($eventThemesData: BulkEventThemeInput!) {
    bulkUpdateEventTheme(eventThemesData: $eventThemesData)
  }
`;
export type BulkUpdateEventThemeMutationFn = Apollo.MutationFunction<
  BulkUpdateEventThemeMutation,
  BulkUpdateEventThemeMutationVariables
>;

/**
 * __useBulkUpdateEventThemeMutation__
 *
 * To run a mutation, you first call `useBulkUpdateEventThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateEventThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateEventThemeMutation, { data, loading, error }] = useBulkUpdateEventThemeMutation({
 *   variables: {
 *      eventThemesData: // value for 'eventThemesData'
 *   },
 * });
 */
export function useBulkUpdateEventThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateEventThemeMutation,
    BulkUpdateEventThemeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateEventThemeMutation,
    BulkUpdateEventThemeMutationVariables
  >(BulkUpdateEventThemeDocument, options);
}
export type BulkUpdateEventThemeMutationHookResult = ReturnType<
  typeof useBulkUpdateEventThemeMutation
>;
export type BulkUpdateEventThemeMutationResult =
  Apollo.MutationResult<BulkUpdateEventThemeMutation>;
export type BulkUpdateEventThemeMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateEventThemeMutation,
  BulkUpdateEventThemeMutationVariables
>;
export const BulkUpdateSettingDocument = gql`
  mutation BulkUpdateSetting($settingsData: BulkSettingInput!) {
    bulkUpdateSetting(settingsData: $settingsData) {
      key
      value
    }
  }
`;
export type BulkUpdateSettingMutationFn = Apollo.MutationFunction<
  BulkUpdateSettingMutation,
  BulkUpdateSettingMutationVariables
>;

/**
 * __useBulkUpdateSettingMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSettingMutation, { data, loading, error }] = useBulkUpdateSettingMutation({
 *   variables: {
 *      settingsData: // value for 'settingsData'
 *   },
 * });
 */
export function useBulkUpdateSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateSettingMutation,
    BulkUpdateSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateSettingMutation,
    BulkUpdateSettingMutationVariables
  >(BulkUpdateSettingDocument, options);
}
export type BulkUpdateSettingMutationHookResult = ReturnType<
  typeof useBulkUpdateSettingMutation
>;
export type BulkUpdateSettingMutationResult =
  Apollo.MutationResult<BulkUpdateSettingMutation>;
export type BulkUpdateSettingMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateSettingMutation,
  BulkUpdateSettingMutationVariables
>;
export const BulkUpdateTranslateDocument = gql`
  mutation BulkUpdateTranslate($translatesData: BulkTranslateInput!) {
    bulkUpdateTranslate(translatesData: $translatesData) {
      key
      strings {
        locale {
          id
          title
          code
          translates {
            key
          }
        }
        string
      }
      texts {
        text
      }
      images {
        image {
          id
          width
          height
          mime
          size
          url
        }
      }
    }
  }
`;
export type BulkUpdateTranslateMutationFn = Apollo.MutationFunction<
  BulkUpdateTranslateMutation,
  BulkUpdateTranslateMutationVariables
>;

/**
 * __useBulkUpdateTranslateMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTranslateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTranslateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTranslateMutation, { data, loading, error }] = useBulkUpdateTranslateMutation({
 *   variables: {
 *      translatesData: // value for 'translatesData'
 *   },
 * });
 */
export function useBulkUpdateTranslateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateTranslateMutation,
    BulkUpdateTranslateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkUpdateTranslateMutation,
    BulkUpdateTranslateMutationVariables
  >(BulkUpdateTranslateDocument, options);
}
export type BulkUpdateTranslateMutationHookResult = ReturnType<
  typeof useBulkUpdateTranslateMutation
>;
export type BulkUpdateTranslateMutationResult =
  Apollo.MutationResult<BulkUpdateTranslateMutation>;
export type BulkUpdateTranslateMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateTranslateMutation,
  BulkUpdateTranslateMutationVariables
>;
export const CreateBrandDocument = gql`
  mutation createBrand($createBrandInput: CreateBrandInput!) {
    createBrand(createBrandInput: $createBrandInput) {
      id
    }
  }
`;
export type CreateBrandMutationFn = Apollo.MutationFunction<
  CreateBrandMutation,
  CreateBrandMutationVariables
>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      createBrandInput: // value for 'createBrandInput'
 *   },
 * });
 */
export function useCreateBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBrandMutation,
    CreateBrandMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(
    CreateBrandDocument,
    options,
  );
}
export type CreateBrandMutationHookResult = ReturnType<
  typeof useCreateBrandMutation
>;
export type CreateBrandMutationResult =
  Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<
  CreateBrandMutation,
  CreateBrandMutationVariables
>;
export const CreateDishDocument = gql`
  mutation createDish($createDishInput: CreateDishInput!) {
    createDish(createDishInput: $createDishInput) {
      id
    }
  }
`;
export type CreateDishMutationFn = Apollo.MutationFunction<
  CreateDishMutation,
  CreateDishMutationVariables
>;

/**
 * __useCreateDishMutation__
 *
 * To run a mutation, you first call `useCreateDishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDishMutation, { data, loading, error }] = useCreateDishMutation({
 *   variables: {
 *      createDishInput: // value for 'createDishInput'
 *   },
 * });
 */
export function useCreateDishMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDishMutation,
    CreateDishMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDishMutation, CreateDishMutationVariables>(
    CreateDishDocument,
    options,
  );
}
export type CreateDishMutationHookResult = ReturnType<
  typeof useCreateDishMutation
>;
export type CreateDishMutationResult =
  Apollo.MutationResult<CreateDishMutation>;
export type CreateDishMutationOptions = Apollo.BaseMutationOptions<
  CreateDishMutation,
  CreateDishMutationVariables
>;
export const CreateDishCategoryDocument = gql`
  mutation createDishCategory(
    $createDishCategoryInput: CreateDishCategoryInput!
  ) {
    createDishCategory(createDishCategoryInput: $createDishCategoryInput) {
      id
    }
  }
`;
export type CreateDishCategoryMutationFn = Apollo.MutationFunction<
  CreateDishCategoryMutation,
  CreateDishCategoryMutationVariables
>;

/**
 * __useCreateDishCategoryMutation__
 *
 * To run a mutation, you first call `useCreateDishCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDishCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDishCategoryMutation, { data, loading, error }] = useCreateDishCategoryMutation({
 *   variables: {
 *      createDishCategoryInput: // value for 'createDishCategoryInput'
 *   },
 * });
 */
export function useCreateDishCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDishCategoryMutation,
    CreateDishCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDishCategoryMutation,
    CreateDishCategoryMutationVariables
  >(CreateDishCategoryDocument, options);
}
export type CreateDishCategoryMutationHookResult = ReturnType<
  typeof useCreateDishCategoryMutation
>;
export type CreateDishCategoryMutationResult =
  Apollo.MutationResult<CreateDishCategoryMutation>;
export type CreateDishCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateDishCategoryMutation,
  CreateDishCategoryMutationVariables
>;
export const CreateManagerDocument = gql`
  mutation CreateManager($createManagerInput: CreateManagerInput!) {
    createManager(createManagerInput: $createManagerInput) {
      id
      email
      firstName
      lastName
      position
      photo {
        id
        width
        height
        mime
        size
        url
      }
      brand {
        id
        title
        description
        isActive
        image {
          id
          width
          height
          mime
          size
          url
        }
        restaurantsCount
      }
      roles
    }
  }
`;
export type CreateManagerMutationFn = Apollo.MutationFunction<
  CreateManagerMutation,
  CreateManagerMutationVariables
>;

/**
 * __useCreateManagerMutation__
 *
 * To run a mutation, you first call `useCreateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagerMutation, { data, loading, error }] = useCreateManagerMutation({
 *   variables: {
 *      createManagerInput: // value for 'createManagerInput'
 *   },
 * });
 */
export function useCreateManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManagerMutation,
    CreateManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManagerMutation,
    CreateManagerMutationVariables
  >(CreateManagerDocument, options);
}
export type CreateManagerMutationHookResult = ReturnType<
  typeof useCreateManagerMutation
>;
export type CreateManagerMutationResult =
  Apollo.MutationResult<CreateManagerMutation>;
export type CreateManagerMutationOptions = Apollo.BaseMutationOptions<
  CreateManagerMutation,
  CreateManagerMutationVariables
>;
export const CreateNotificationDocument = gql`
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input)
  }
`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >(CreateNotificationDocument, options);
}
export type CreateNotificationMutationHookResult = ReturnType<
  typeof useCreateNotificationMutation
>;
export type CreateNotificationMutationResult =
  Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;
export const CreateOfferDocument = gql`
  mutation CreateOffer($createOfferInput: CreateOfferInput!) {
    createOffer(createOfferInput: $createOfferInput) {
      id
    }
  }
`;
export type CreateOfferMutationFn = Apollo.MutationFunction<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      createOfferInput: // value for 'createOfferInput'
 *   },
 * });
 */
export function useCreateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOfferMutation,
    CreateOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(
    CreateOfferDocument,
    options,
  );
}
export type CreateOfferMutationHookResult = ReturnType<
  typeof useCreateOfferMutation
>;
export type CreateOfferMutationResult =
  Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;
export const CreateRegistrationApplicationDocument = gql`
  mutation createRegistrationApplication(
    $createRegistrationApplicationInput: CreateRegistrationApplicationInput!
  ) {
    createRegistrationApplication(
      createRegistrationApplicationInput: $createRegistrationApplicationInput
    ) {
      id
      name
      email
      phone
      restaurantName
      country
      city
      createdAt
    }
  }
`;
export type CreateRegistrationApplicationMutationFn = Apollo.MutationFunction<
  CreateRegistrationApplicationMutation,
  CreateRegistrationApplicationMutationVariables
>;

/**
 * __useCreateRegistrationApplicationMutation__
 *
 * To run a mutation, you first call `useCreateRegistrationApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistrationApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistrationApplicationMutation, { data, loading, error }] = useCreateRegistrationApplicationMutation({
 *   variables: {
 *      createRegistrationApplicationInput: // value for 'createRegistrationApplicationInput'
 *   },
 * });
 */
export function useCreateRegistrationApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRegistrationApplicationMutation,
    CreateRegistrationApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRegistrationApplicationMutation,
    CreateRegistrationApplicationMutationVariables
  >(CreateRegistrationApplicationDocument, options);
}
export type CreateRegistrationApplicationMutationHookResult = ReturnType<
  typeof useCreateRegistrationApplicationMutation
>;
export type CreateRegistrationApplicationMutationResult =
  Apollo.MutationResult<CreateRegistrationApplicationMutation>;
export type CreateRegistrationApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRegistrationApplicationMutation,
    CreateRegistrationApplicationMutationVariables
  >;
export const CreateRemindEventOfferNotificationDocument = gql`
  mutation createRemindEventOfferNotification($eventOfferId: Int!) {
    createRemindEventOfferNotification(eventOfferId: $eventOfferId)
  }
`;
export type CreateRemindEventOfferNotificationMutationFn =
  Apollo.MutationFunction<
    CreateRemindEventOfferNotificationMutation,
    CreateRemindEventOfferNotificationMutationVariables
  >;

/**
 * __useCreateRemindEventOfferNotificationMutation__
 *
 * To run a mutation, you first call `useCreateRemindEventOfferNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRemindEventOfferNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRemindEventOfferNotificationMutation, { data, loading, error }] = useCreateRemindEventOfferNotificationMutation({
 *   variables: {
 *      eventOfferId: // value for 'eventOfferId'
 *   },
 * });
 */
export function useCreateRemindEventOfferNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRemindEventOfferNotificationMutation,
    CreateRemindEventOfferNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRemindEventOfferNotificationMutation,
    CreateRemindEventOfferNotificationMutationVariables
  >(CreateRemindEventOfferNotificationDocument, options);
}
export type CreateRemindEventOfferNotificationMutationHookResult = ReturnType<
  typeof useCreateRemindEventOfferNotificationMutation
>;
export type CreateRemindEventOfferNotificationMutationResult =
  Apollo.MutationResult<CreateRemindEventOfferNotificationMutation>;
export type CreateRemindEventOfferNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRemindEventOfferNotificationMutation,
    CreateRemindEventOfferNotificationMutationVariables
  >;
export const CreateRestaurantDocument = gql`
  mutation CreateRestaurant($createRestaurantInput: CreateRestaurantInput!) {
    createRestaurant(createRestaurantInput: $createRestaurantInput) {
      id
      name
      scheduleType
      priceLevel
      description
      averageCost
      address
      latitude
      longitude
      availableSeatsNumber
      isClosed
      createdAt
      updatedAt
      image {
        id
        width
        height
        mime
        size
        url
      }
      city {
        id
        title
      }
      workDays {
        id
        day
        from
        to
        isActive
      }
      secondImage {
        id
        width
        height
        mime
        size
        url
      }
    }
  }
`;
export type CreateRestaurantMutationFn = Apollo.MutationFunction<
  CreateRestaurantMutation,
  CreateRestaurantMutationVariables
>;

/**
 * __useCreateRestaurantMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantMutation, { data, loading, error }] = useCreateRestaurantMutation({
 *   variables: {
 *      createRestaurantInput: // value for 'createRestaurantInput'
 *   },
 * });
 */
export function useCreateRestaurantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRestaurantMutation,
    CreateRestaurantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRestaurantMutation,
    CreateRestaurantMutationVariables
  >(CreateRestaurantDocument, options);
}
export type CreateRestaurantMutationHookResult = ReturnType<
  typeof useCreateRestaurantMutation
>;
export type CreateRestaurantMutationResult =
  Apollo.MutationResult<CreateRestaurantMutation>;
export type CreateRestaurantMutationOptions = Apollo.BaseMutationOptions<
  CreateRestaurantMutation,
  CreateRestaurantMutationVariables
>;
export const DishesInRestaurantDocument = gql`
  mutation dishesInRestaurant(
    $dishesInRestaurantInput: DishesInRestaurantInput!
  ) {
    dishesInRestaurant(dishesInRestaurantInput: $dishesInRestaurantInput)
  }
`;
export type DishesInRestaurantMutationFn = Apollo.MutationFunction<
  DishesInRestaurantMutation,
  DishesInRestaurantMutationVariables
>;

/**
 * __useDishesInRestaurantMutation__
 *
 * To run a mutation, you first call `useDishesInRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDishesInRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dishesInRestaurantMutation, { data, loading, error }] = useDishesInRestaurantMutation({
 *   variables: {
 *      dishesInRestaurantInput: // value for 'dishesInRestaurantInput'
 *   },
 * });
 */
export function useDishesInRestaurantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DishesInRestaurantMutation,
    DishesInRestaurantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DishesInRestaurantMutation,
    DishesInRestaurantMutationVariables
  >(DishesInRestaurantDocument, options);
}
export type DishesInRestaurantMutationHookResult = ReturnType<
  typeof useDishesInRestaurantMutation
>;
export type DishesInRestaurantMutationResult =
  Apollo.MutationResult<DishesInRestaurantMutation>;
export type DishesInRestaurantMutationOptions = Apollo.BaseMutationOptions<
  DishesInRestaurantMutation,
  DishesInRestaurantMutationVariables
>;
export const ManagerRestorePasswordDocument = gql`
  mutation ManagerRestorePassword($email: String!) {
    managerRestorePassword(email: $email)
  }
`;
export type ManagerRestorePasswordMutationFn = Apollo.MutationFunction<
  ManagerRestorePasswordMutation,
  ManagerRestorePasswordMutationVariables
>;

/**
 * __useManagerRestorePasswordMutation__
 *
 * To run a mutation, you first call `useManagerRestorePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerRestorePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerRestorePasswordMutation, { data, loading, error }] = useManagerRestorePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useManagerRestorePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManagerRestorePasswordMutation,
    ManagerRestorePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManagerRestorePasswordMutation,
    ManagerRestorePasswordMutationVariables
  >(ManagerRestorePasswordDocument, options);
}
export type ManagerRestorePasswordMutationHookResult = ReturnType<
  typeof useManagerRestorePasswordMutation
>;
export type ManagerRestorePasswordMutationResult =
  Apollo.MutationResult<ManagerRestorePasswordMutation>;
export type ManagerRestorePasswordMutationOptions = Apollo.BaseMutationOptions<
  ManagerRestorePasswordMutation,
  ManagerRestorePasswordMutationVariables
>;
export const OffersToFavoritesDocument = gql`
  mutation OffersToFavorites($updates: UpdateOffersIsFavoriteStatusInput!) {
    offersToFavorites(updates: $updates)
  }
`;
export type OffersToFavoritesMutationFn = Apollo.MutationFunction<
  OffersToFavoritesMutation,
  OffersToFavoritesMutationVariables
>;

/**
 * __useOffersToFavoritesMutation__
 *
 * To run a mutation, you first call `useOffersToFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOffersToFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offersToFavoritesMutation, { data, loading, error }] = useOffersToFavoritesMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useOffersToFavoritesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OffersToFavoritesMutation,
    OffersToFavoritesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OffersToFavoritesMutation,
    OffersToFavoritesMutationVariables
  >(OffersToFavoritesDocument, options);
}
export type OffersToFavoritesMutationHookResult = ReturnType<
  typeof useOffersToFavoritesMutation
>;
export type OffersToFavoritesMutationResult =
  Apollo.MutationResult<OffersToFavoritesMutation>;
export type OffersToFavoritesMutationOptions = Apollo.BaseMutationOptions<
  OffersToFavoritesMutation,
  OffersToFavoritesMutationVariables
>;
export const ParseCityDocument = gql`
  mutation parseCity($cityId: Int!) {
    parseCity(cityId: $cityId) {
      id
    }
  }
`;
export type ParseCityMutationFn = Apollo.MutationFunction<
  ParseCityMutation,
  ParseCityMutationVariables
>;

/**
 * __useParseCityMutation__
 *
 * To run a mutation, you first call `useParseCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseCityMutation, { data, loading, error }] = useParseCityMutation({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useParseCityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ParseCityMutation,
    ParseCityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ParseCityMutation, ParseCityMutationVariables>(
    ParseCityDocument,
    options,
  );
}
export type ParseCityMutationHookResult = ReturnType<
  typeof useParseCityMutation
>;
export type ParseCityMutationResult = Apollo.MutationResult<ParseCityMutation>;
export type ParseCityMutationOptions = Apollo.BaseMutationOptions<
  ParseCityMutation,
  ParseCityMutationVariables
>;
export const RemoveBrandDocument = gql`
  mutation removeBrand($id: Int!) {
    removeBrand(id: $id)
  }
`;
export type RemoveBrandMutationFn = Apollo.MutationFunction<
  RemoveBrandMutation,
  RemoveBrandMutationVariables
>;

/**
 * __useRemoveBrandMutation__
 *
 * To run a mutation, you first call `useRemoveBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBrandMutation, { data, loading, error }] = useRemoveBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBrandMutation,
    RemoveBrandMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveBrandMutation, RemoveBrandMutationVariables>(
    RemoveBrandDocument,
    options,
  );
}
export type RemoveBrandMutationHookResult = ReturnType<
  typeof useRemoveBrandMutation
>;
export type RemoveBrandMutationResult =
  Apollo.MutationResult<RemoveBrandMutation>;
export type RemoveBrandMutationOptions = Apollo.BaseMutationOptions<
  RemoveBrandMutation,
  RemoveBrandMutationVariables
>;
export const RemoveDishDocument = gql`
  mutation removeDish($id: Int!) {
    removeDish(id: $id)
  }
`;
export type RemoveDishMutationFn = Apollo.MutationFunction<
  RemoveDishMutation,
  RemoveDishMutationVariables
>;

/**
 * __useRemoveDishMutation__
 *
 * To run a mutation, you first call `useRemoveDishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDishMutation, { data, loading, error }] = useRemoveDishMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDishMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDishMutation,
    RemoveDishMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDishMutation, RemoveDishMutationVariables>(
    RemoveDishDocument,
    options,
  );
}
export type RemoveDishMutationHookResult = ReturnType<
  typeof useRemoveDishMutation
>;
export type RemoveDishMutationResult =
  Apollo.MutationResult<RemoveDishMutation>;
export type RemoveDishMutationOptions = Apollo.BaseMutationOptions<
  RemoveDishMutation,
  RemoveDishMutationVariables
>;
export const RemoveManagerDocument = gql`
  mutation RemoveManager($removeManagerId: Int!) {
    removeManager(id: $removeManagerId) {
      id
    }
  }
`;
export type RemoveManagerMutationFn = Apollo.MutationFunction<
  RemoveManagerMutation,
  RemoveManagerMutationVariables
>;

/**
 * __useRemoveManagerMutation__
 *
 * To run a mutation, you first call `useRemoveManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerMutation, { data, loading, error }] = useRemoveManagerMutation({
 *   variables: {
 *      removeManagerId: // value for 'removeManagerId'
 *   },
 * });
 */
export function useRemoveManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveManagerMutation,
    RemoveManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveManagerMutation,
    RemoveManagerMutationVariables
  >(RemoveManagerDocument, options);
}
export type RemoveManagerMutationHookResult = ReturnType<
  typeof useRemoveManagerMutation
>;
export type RemoveManagerMutationResult =
  Apollo.MutationResult<RemoveManagerMutation>;
export type RemoveManagerMutationOptions = Apollo.BaseMutationOptions<
  RemoveManagerMutation,
  RemoveManagerMutationVariables
>;
export const RemoveTranslateDocument = gql`
  mutation RemoveTranslate($key: String!) {
    removeTranslate(key: $key)
  }
`;
export type RemoveTranslateMutationFn = Apollo.MutationFunction<
  RemoveTranslateMutation,
  RemoveTranslateMutationVariables
>;

/**
 * __useRemoveTranslateMutation__
 *
 * To run a mutation, you first call `useRemoveTranslateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTranslateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTranslateMutation, { data, loading, error }] = useRemoveTranslateMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useRemoveTranslateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTranslateMutation,
    RemoveTranslateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTranslateMutation,
    RemoveTranslateMutationVariables
  >(RemoveTranslateDocument, options);
}
export type RemoveTranslateMutationHookResult = ReturnType<
  typeof useRemoveTranslateMutation
>;
export type RemoveTranslateMutationResult =
  Apollo.MutationResult<RemoveTranslateMutation>;
export type RemoveTranslateMutationOptions = Apollo.BaseMutationOptions<
  RemoveTranslateMutation,
  RemoveTranslateMutationVariables
>;
export const ManagerSignInDocument = gql`
  mutation managerSignIn($managerSignInInput: ManagerSignInInput!) {
    managerSignIn(managerSignInInput: $managerSignInInput) {
      accessToken
      manager {
        id
        email
        firstName
        lastName
        position
        photo {
          width
          url
          size
          mime
          id
          height
        }
        brand {
          id
        }
        roles
      }
    }
  }
`;
export type ManagerSignInMutationFn = Apollo.MutationFunction<
  ManagerSignInMutation,
  ManagerSignInMutationVariables
>;

/**
 * __useManagerSignInMutation__
 *
 * To run a mutation, you first call `useManagerSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerSignInMutation, { data, loading, error }] = useManagerSignInMutation({
 *   variables: {
 *      managerSignInInput: // value for 'managerSignInInput'
 *   },
 * });
 */
export function useManagerSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManagerSignInMutation,
    ManagerSignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManagerSignInMutation,
    ManagerSignInMutationVariables
  >(ManagerSignInDocument, options);
}
export type ManagerSignInMutationHookResult = ReturnType<
  typeof useManagerSignInMutation
>;
export type ManagerSignInMutationResult =
  Apollo.MutationResult<ManagerSignInMutation>;
export type ManagerSignInMutationOptions = Apollo.BaseMutationOptions<
  ManagerSignInMutation,
  ManagerSignInMutationVariables
>;
export const SignInAsManagerDocument = gql`
  mutation SignInAsManager($signInAsManagerId: Int!) {
    signInAsManager(id: $signInAsManagerId) {
      accessToken
      manager {
        id
        email
        firstName
        lastName
        position
        photo {
          id
          width
          height
          mime
          size
          url
        }
        brand {
          id
        }
        roles
      }
    }
  }
`;
export type SignInAsManagerMutationFn = Apollo.MutationFunction<
  SignInAsManagerMutation,
  SignInAsManagerMutationVariables
>;

/**
 * __useSignInAsManagerMutation__
 *
 * To run a mutation, you first call `useSignInAsManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInAsManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInAsManagerMutation, { data, loading, error }] = useSignInAsManagerMutation({
 *   variables: {
 *      signInAsManagerId: // value for 'signInAsManagerId'
 *   },
 * });
 */
export function useSignInAsManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInAsManagerMutation,
    SignInAsManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignInAsManagerMutation,
    SignInAsManagerMutationVariables
  >(SignInAsManagerDocument, options);
}
export type SignInAsManagerMutationHookResult = ReturnType<
  typeof useSignInAsManagerMutation
>;
export type SignInAsManagerMutationResult =
  Apollo.MutationResult<SignInAsManagerMutation>;
export type SignInAsManagerMutationOptions = Apollo.BaseMutationOptions<
  SignInAsManagerMutation,
  SignInAsManagerMutationVariables
>;
export const UpdateBrandDocument = gql`
  mutation updateBrand($input: EditBrandInput!) {
    updateBrand(input: $input) {
      id
    }
  }
`;
export type UpdateBrandMutationFn = Apollo.MutationFunction<
  UpdateBrandMutation,
  UpdateBrandMutationVariables
>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBrandMutation,
    UpdateBrandMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(
    UpdateBrandDocument,
    options,
  );
}
export type UpdateBrandMutationHookResult = ReturnType<
  typeof useUpdateBrandMutation
>;
export type UpdateBrandMutationResult =
  Apollo.MutationResult<UpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<
  UpdateBrandMutation,
  UpdateBrandMutationVariables
>;
export const UpdateDishDocument = gql`
  mutation updateDish($updateDishInput: UpdateDishInput!) {
    updateDish(updateDishInput: $updateDishInput) {
      id
    }
  }
`;
export type UpdateDishMutationFn = Apollo.MutationFunction<
  UpdateDishMutation,
  UpdateDishMutationVariables
>;

/**
 * __useUpdateDishMutation__
 *
 * To run a mutation, you first call `useUpdateDishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDishMutation, { data, loading, error }] = useUpdateDishMutation({
 *   variables: {
 *      updateDishInput: // value for 'updateDishInput'
 *   },
 * });
 */
export function useUpdateDishMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDishMutation,
    UpdateDishMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDishMutation, UpdateDishMutationVariables>(
    UpdateDishDocument,
    options,
  );
}
export type UpdateDishMutationHookResult = ReturnType<
  typeof useUpdateDishMutation
>;
export type UpdateDishMutationResult =
  Apollo.MutationResult<UpdateDishMutation>;
export type UpdateDishMutationOptions = Apollo.BaseMutationOptions<
  UpdateDishMutation,
  UpdateDishMutationVariables
>;
export const UpdateEventOfferDocument = gql`
  mutation UpdateEventOffer($updates: UpdateEventOfferInput!) {
    updateEventOffer(updates: $updates) {
      id
    }
  }
`;
export type UpdateEventOfferMutationFn = Apollo.MutationFunction<
  UpdateEventOfferMutation,
  UpdateEventOfferMutationVariables
>;

/**
 * __useUpdateEventOfferMutation__
 *
 * To run a mutation, you first call `useUpdateEventOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventOfferMutation, { data, loading, error }] = useUpdateEventOfferMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateEventOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventOfferMutation,
    UpdateEventOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEventOfferMutation,
    UpdateEventOfferMutationVariables
  >(UpdateEventOfferDocument, options);
}
export type UpdateEventOfferMutationHookResult = ReturnType<
  typeof useUpdateEventOfferMutation
>;
export type UpdateEventOfferMutationResult =
  Apollo.MutationResult<UpdateEventOfferMutation>;
export type UpdateEventOfferMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventOfferMutation,
  UpdateEventOfferMutationVariables
>;
export const UpdateManagerDocument = gql`
  mutation UpdateManager($updateManagerInput: UpdateManagerInput!) {
    updateManager(updateManagerInput: $updateManagerInput) {
      id
      email
      firstName
      lastName
      position
      photo {
        id
        width
        height
        mime
        size
        url
      }
      roles
    }
  }
`;
export type UpdateManagerMutationFn = Apollo.MutationFunction<
  UpdateManagerMutation,
  UpdateManagerMutationVariables
>;

/**
 * __useUpdateManagerMutation__
 *
 * To run a mutation, you first call `useUpdateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerMutation, { data, loading, error }] = useUpdateManagerMutation({
 *   variables: {
 *      updateManagerInput: // value for 'updateManagerInput'
 *   },
 * });
 */
export function useUpdateManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagerMutation,
    UpdateManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateManagerMutation,
    UpdateManagerMutationVariables
  >(UpdateManagerDocument, options);
}
export type UpdateManagerMutationHookResult = ReturnType<
  typeof useUpdateManagerMutation
>;
export type UpdateManagerMutationResult =
  Apollo.MutationResult<UpdateManagerMutation>;
export type UpdateManagerMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagerMutation,
  UpdateManagerMutationVariables
>;
export const UpdateOfferDocument = gql`
  mutation UpdateOffer($updateOfferInput: UpdateOfferInput!) {
    updateOffer(updateOfferInput: $updateOfferInput) {
      id
      name
      description
      amountPersons
      budgetPerPerson
      status
      isFavorite
      brand {
        id
        title
        description
        isActive
        restaurantsCount
      }
      image {
        id
        width
        height
        mime
        size
        url
      }
      workDays {
        id
        day
        from
        to
        isActive
      }
      cuisines {
        id
        title
      }
      eventThemes {
        id
        title
        isFavorite
      }
      offerDishes {
        quantity
        dishId
        offerId
        dish {
          id
          title
          description
          brandId
          dishCategoryId
          price
          cookingTime
          amountPersons
          calories
          weight
          volume
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdateOfferMutationFn = Apollo.MutationFunction<
  UpdateOfferMutation,
  UpdateOfferMutationVariables
>;

/**
 * __useUpdateOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferMutation, { data, loading, error }] = useUpdateOfferMutation({
 *   variables: {
 *      updateOfferInput: // value for 'updateOfferInput'
 *   },
 * });
 */
export function useUpdateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOfferMutation,
    UpdateOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(
    UpdateOfferDocument,
    options,
  );
}
export type UpdateOfferMutationHookResult = ReturnType<
  typeof useUpdateOfferMutation
>;
export type UpdateOfferMutationResult =
  Apollo.MutationResult<UpdateOfferMutation>;
export type UpdateOfferMutationOptions = Apollo.BaseMutationOptions<
  UpdateOfferMutation,
  UpdateOfferMutationVariables
>;
export const UpdateRestaurantDocument = gql`
  mutation updateRestaurant($updateRestaurantInput: UpdateRestaurantInput!) {
    updateRestaurant(updateRestaurantInput: $updateRestaurantInput) {
      id
      name
      scheduleType
      priceLevel
      description
      averageCost
      address
      latitude
      longitude
      availableSeatsNumber
      isClosed
      createdAt
      updatedAt
      image {
        id
        width
        height
        mime
        size
        url
      }
      city {
        id
        title
      }
      workDays {
        id
        day
        from
        to
        isActive
      }
      secondImage {
        id
        width
        height
        mime
        size
        url
      }
    }
  }
`;
export type UpdateRestaurantMutationFn = Apollo.MutationFunction<
  UpdateRestaurantMutation,
  UpdateRestaurantMutationVariables
>;

/**
 * __useUpdateRestaurantMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantMutation, { data, loading, error }] = useUpdateRestaurantMutation({
 *   variables: {
 *      updateRestaurantInput: // value for 'updateRestaurantInput'
 *   },
 * });
 */
export function useUpdateRestaurantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRestaurantMutation,
    UpdateRestaurantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRestaurantMutation,
    UpdateRestaurantMutationVariables
  >(UpdateRestaurantDocument, options);
}
export type UpdateRestaurantMutationHookResult = ReturnType<
  typeof useUpdateRestaurantMutation
>;
export type UpdateRestaurantMutationResult =
  Apollo.MutationResult<UpdateRestaurantMutation>;
export type UpdateRestaurantMutationOptions = Apollo.BaseMutationOptions<
  UpdateRestaurantMutation,
  UpdateRestaurantMutationVariables
>;
export const ActiveOffersDocument = gql`
  query activeOffers($options: GetOffersAsAdminInput!) {
    activeOffers(options: $options) {
      rows {
        id
        name
        description
        amountPersons
        budgetPerPerson
        status
        isFavorite
        brand {
          id
          title
          description
          isActive
          restaurantsCount
        }
        image {
          id
          width
          height
          mime
          size
          url
        }
        restaurants {
          id
          name
          scheduleType
          priceLevel
          description
          averageCost
          address
          latitude
          longitude
          availableSeatsNumber
          isClosed
          brandId
          createdAt
          updatedAt
          closedFrom
          closedTo
          city {
            id
            title
            latitude
            longitude
          }
          image {
            id
            width
            height
            mime
            size
            url
          }
        }
        cuisines {
          id
          title
        }
        eventThemes {
          id
          title
          isFavorite
        }
        offerDishes {
          quantity
          dishId
          offerId
          dish {
            id
            title
            description
            brandId
            price
            cookingTime
            amountPersons
            calories
            weight
            volume
          }
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

/**
 * __useActiveOffersQuery__
 *
 * To run a query within a React component, call `useActiveOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveOffersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useActiveOffersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveOffersQuery,
    ActiveOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveOffersQuery, ActiveOffersQueryVariables>(
    ActiveOffersDocument,
    options,
  );
}
export function useActiveOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveOffersQuery,
    ActiveOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveOffersQuery, ActiveOffersQueryVariables>(
    ActiveOffersDocument,
    options,
  );
}
export type ActiveOffersQueryHookResult = ReturnType<
  typeof useActiveOffersQuery
>;
export type ActiveOffersLazyQueryHookResult = ReturnType<
  typeof useActiveOffersLazyQuery
>;
export type ActiveOffersQueryResult = Apollo.QueryResult<
  ActiveOffersQuery,
  ActiveOffersQueryVariables
>;
export const AdvicesDocument = gql`
  query Advices {
    advices {
      id
      text
    }
  }
`;

/**
 * __useAdvicesQuery__
 *
 * To run a query within a React component, call `useAdvicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvicesQuery(
  baseOptions?: Apollo.QueryHookOptions<AdvicesQuery, AdvicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdvicesQuery, AdvicesQueryVariables>(
    AdvicesDocument,
    options,
  );
}
export function useAdvicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdvicesQuery,
    AdvicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdvicesQuery, AdvicesQueryVariables>(
    AdvicesDocument,
    options,
  );
}
export type AdvicesQueryHookResult = ReturnType<typeof useAdvicesQuery>;
export type AdvicesLazyQueryHookResult = ReturnType<typeof useAdvicesLazyQuery>;
export type AdvicesQueryResult = Apollo.QueryResult<
  AdvicesQuery,
  AdvicesQueryVariables
>;
export const BrandDocument = gql`
  query Brand($brandId: Int!) {
    brand(id: $brandId) {
      id
      title
      description
      isActive
      image {
        id
        width
        height
        mime
        size
        url
      }
      restaurantsCount
    }
  }
`;

/**
 * __useBrandQuery__
 *
 * To run a query within a React component, call `useBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useBrandQuery(
  baseOptions: Apollo.QueryHookOptions<BrandQuery, BrandQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandQuery, BrandQueryVariables>(
    BrandDocument,
    options,
  );
}
export function useBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrandQuery, BrandQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandQuery, BrandQueryVariables>(
    BrandDocument,
    options,
  );
}
export type BrandQueryHookResult = ReturnType<typeof useBrandQuery>;
export type BrandLazyQueryHookResult = ReturnType<typeof useBrandLazyQuery>;
export type BrandQueryResult = Apollo.QueryResult<
  BrandQuery,
  BrandQueryVariables
>;
export const BrandEventOfferDocument = gql`
  query BrandEventOffer($brandEventOfferId: Int!) {
    brandEventOffer(id: $brandEventOfferId) {
      id
      suitableRestaurantsIds
      brandId
      event {
        id
        startAt
        name
        budgetPerPerson
        amountPersons
        tipPercentage
        endAt
      }
      eventOfferDishes {
        quantity
        eventOfferId
        dishId
        dish {
          price
          amountPersons
          image {
            url
          }
          title
        }
      }
    }
  }
`;

/**
 * __useBrandEventOfferQuery__
 *
 * To run a query within a React component, call `useBrandEventOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandEventOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandEventOfferQuery({
 *   variables: {
 *      brandEventOfferId: // value for 'brandEventOfferId'
 *   },
 * });
 */
export function useBrandEventOfferQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrandEventOfferQuery,
    BrandEventOfferQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandEventOfferQuery, BrandEventOfferQueryVariables>(
    BrandEventOfferDocument,
    options,
  );
}
export function useBrandEventOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrandEventOfferQuery,
    BrandEventOfferQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrandEventOfferQuery,
    BrandEventOfferQueryVariables
  >(BrandEventOfferDocument, options);
}
export type BrandEventOfferQueryHookResult = ReturnType<
  typeof useBrandEventOfferQuery
>;
export type BrandEventOfferLazyQueryHookResult = ReturnType<
  typeof useBrandEventOfferLazyQuery
>;
export type BrandEventOfferQueryResult = Apollo.QueryResult<
  BrandEventOfferQuery,
  BrandEventOfferQueryVariables
>;
export const BrandEventOffersDocument = gql`
  query BrandEventOffers($options: GetEventOffersInput!) {
    brandEventOffers(options: $options) {
      id
      comment
      selectedRestaurantId
      status
      suitableRestaurantsIds
      event {
        name
        ratingFrom
        budgetPerPerson
        amountPersons
        eventTheme {
          id
          title
          isFavorite
        }
        repeatOnceAt
        startAt
        endAt
        user {
          id
        }
        updatedAt
      }
      eventOfferDishes {
        dish {
          image {
            url
          }
          price
          title
          id
          description
        }
        quantity
        eventOfferId
        dishId
      }
      total
      discount
      restaurantReview {
        accurateRating
        eventMatchRating
        cuisineRating
        serviceRating
        avgRating
        comment
        createdAt
        user {
          name
          photo {
            url
          }
        }
      }
    }
  }
`;

/**
 * __useBrandEventOffersQuery__
 *
 * To run a query within a React component, call `useBrandEventOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandEventOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandEventOffersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBrandEventOffersQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrandEventOffersQuery,
    BrandEventOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandEventOffersQuery, BrandEventOffersQueryVariables>(
    BrandEventOffersDocument,
    options,
  );
}
export function useBrandEventOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrandEventOffersQuery,
    BrandEventOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrandEventOffersQuery,
    BrandEventOffersQueryVariables
  >(BrandEventOffersDocument, options);
}
export type BrandEventOffersQueryHookResult = ReturnType<
  typeof useBrandEventOffersQuery
>;
export type BrandEventOffersLazyQueryHookResult = ReturnType<
  typeof useBrandEventOffersLazyQuery
>;
export type BrandEventOffersQueryResult = Apollo.QueryResult<
  BrandEventOffersQuery,
  BrandEventOffersQueryVariables
>;
export const BrandsDocument = gql`
  query Brands($options: GetBrandsInput!) {
    brands(options: $options) {
      rows {
        id
        title
        description
        isActive
        image {
          id
          width
          height
          mime
          size
          url
        }
        restaurantsCount
      }
      count
    }
  }
`;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useBrandsQuery(
  baseOptions: Apollo.QueryHookOptions<BrandsQuery, BrandsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandsQuery, BrandsQueryVariables>(
    BrandsDocument,
    options,
  );
}
export function useBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandsQuery, BrandsQueryVariables>(
    BrandsDocument,
    options,
  );
}
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = Apollo.QueryResult<
  BrandsQuery,
  BrandsQueryVariables
>;
export const CitiesDocument = gql`
  query Cities {
    cities {
      id
      title
      latitude
      longitude
      parserLastParsedDate
      parserCode
      parserStatus
      countryCode
    }
  }
`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  );
}
export function useCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  );
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<
  CitiesQuery,
  CitiesQueryVariables
>;
export const CountFavoritesDocument = gql`
  query countFavorites {
    countFavorites
  }
`;

/**
 * __useCountFavoritesQuery__
 *
 * To run a query within a React component, call `useCountFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountFavoritesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountFavoritesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountFavoritesQuery,
    CountFavoritesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountFavoritesQuery, CountFavoritesQueryVariables>(
    CountFavoritesDocument,
    options,
  );
}
export function useCountFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountFavoritesQuery,
    CountFavoritesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountFavoritesQuery, CountFavoritesQueryVariables>(
    CountFavoritesDocument,
    options,
  );
}
export type CountFavoritesQueryHookResult = ReturnType<
  typeof useCountFavoritesQuery
>;
export type CountFavoritesLazyQueryHookResult = ReturnType<
  typeof useCountFavoritesLazyQuery
>;
export type CountFavoritesQueryResult = Apollo.QueryResult<
  CountFavoritesQuery,
  CountFavoritesQueryVariables
>;
export const CountriesDocument = gql`
  query Countries {
    countries {
      code
      name
    }
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    options,
  );
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    options,
  );
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<
  typeof useCountriesLazyQuery
>;
export type CountriesQueryResult = Apollo.QueryResult<
  CountriesQuery,
  CountriesQueryVariables
>;
export const CuisinesDocument = gql`
  query cuisines {
    cuisines {
      id
      title
      image {
        url
      }
    }
  }
`;

/**
 * __useCuisinesQuery__
 *
 * To run a query within a React component, call `useCuisinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCuisinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCuisinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCuisinesQuery(
  baseOptions?: Apollo.QueryHookOptions<CuisinesQuery, CuisinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CuisinesQuery, CuisinesQueryVariables>(
    CuisinesDocument,
    options,
  );
}
export function useCuisinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CuisinesQuery,
    CuisinesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CuisinesQuery, CuisinesQueryVariables>(
    CuisinesDocument,
    options,
  );
}
export type CuisinesQueryHookResult = ReturnType<typeof useCuisinesQuery>;
export type CuisinesLazyQueryHookResult = ReturnType<
  typeof useCuisinesLazyQuery
>;
export type CuisinesQueryResult = Apollo.QueryResult<
  CuisinesQuery,
  CuisinesQueryVariables
>;
export const DishDocument = gql`
  query dish($id: Int!) {
    dish(id: $id) {
      id
      title
      description
      brandId
      dishCategoryId
      image {
        url
      }
      price
      cookingTime
      amountPersons
      calories
      weight
      volume
    }
  }
`;

/**
 * __useDishQuery__
 *
 * To run a query within a React component, call `useDishQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDishQuery(
  baseOptions: Apollo.QueryHookOptions<DishQuery, DishQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DishQuery, DishQueryVariables>(DishDocument, options);
}
export function useDishLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DishQuery, DishQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DishQuery, DishQueryVariables>(
    DishDocument,
    options,
  );
}
export type DishQueryHookResult = ReturnType<typeof useDishQuery>;
export type DishLazyQueryHookResult = ReturnType<typeof useDishLazyQuery>;
export type DishQueryResult = Apollo.QueryResult<DishQuery, DishQueryVariables>;
export const DishCategoriesDocument = gql`
  query dishCategories($brandId: Int!) {
    dishCategories(brandId: $brandId) {
      id
      title
      countDishes
      brandId
    }
  }
`;

/**
 * __useDishCategoriesQuery__
 *
 * To run a query within a React component, call `useDishCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishCategoriesQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useDishCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DishCategoriesQuery,
    DishCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DishCategoriesQuery, DishCategoriesQueryVariables>(
    DishCategoriesDocument,
    options,
  );
}
export function useDishCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DishCategoriesQuery,
    DishCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DishCategoriesQuery, DishCategoriesQueryVariables>(
    DishCategoriesDocument,
    options,
  );
}
export type DishCategoriesQueryHookResult = ReturnType<
  typeof useDishCategoriesQuery
>;
export type DishCategoriesLazyQueryHookResult = ReturnType<
  typeof useDishCategoriesLazyQuery
>;
export type DishCategoriesQueryResult = Apollo.QueryResult<
  DishCategoriesQuery,
  DishCategoriesQueryVariables
>;
export const DishesDocument = gql`
  query dishes($options: DishesArgs!) {
    dishes(options: $options) {
      rows {
        id
        title
        description
        brandId
        dishCategoryId
        dishCategory {
          id
          title
        }
        image {
          url
        }
        price
        cookingTime
        amountPersons
        calories
        weight
        volume
      }
      count
    }
  }
`;

/**
 * __useDishesQuery__
 *
 * To run a query within a React component, call `useDishesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDishesQuery(
  baseOptions: Apollo.QueryHookOptions<DishesQuery, DishesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DishesQuery, DishesQueryVariables>(
    DishesDocument,
    options,
  );
}
export function useDishesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DishesQuery, DishesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DishesQuery, DishesQueryVariables>(
    DishesDocument,
    options,
  );
}
export type DishesQueryHookResult = ReturnType<typeof useDishesQuery>;
export type DishesLazyQueryHookResult = ReturnType<typeof useDishesLazyQuery>;
export type DishesQueryResult = Apollo.QueryResult<
  DishesQuery,
  DishesQueryVariables
>;
export const EventOfferReviewDocument = gql`
  query eventOfferReview($options: GetEventOfferReviewInput!) {
    eventOfferReview(options: $options) {
      accurateRating
      eventMatchRating
      cuisineRating
      serviceRating
      avgRating
      comment
      createdAt
      user {
        name
        photo {
          url
        }
      }
    }
  }
`;

/**
 * __useEventOfferReviewQuery__
 *
 * To run a query within a React component, call `useEventOfferReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventOfferReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventOfferReviewQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useEventOfferReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventOfferReviewQuery,
    EventOfferReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventOfferReviewQuery, EventOfferReviewQueryVariables>(
    EventOfferReviewDocument,
    options,
  );
}
export function useEventOfferReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventOfferReviewQuery,
    EventOfferReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EventOfferReviewQuery,
    EventOfferReviewQueryVariables
  >(EventOfferReviewDocument, options);
}
export type EventOfferReviewQueryHookResult = ReturnType<
  typeof useEventOfferReviewQuery
>;
export type EventOfferReviewLazyQueryHookResult = ReturnType<
  typeof useEventOfferReviewLazyQuery
>;
export type EventOfferReviewQueryResult = Apollo.QueryResult<
  EventOfferReviewQuery,
  EventOfferReviewQueryVariables
>;
export const EventThemesDocument = gql`
  query EventThemes {
    eventThemes {
      id
      title
      isFavorite
      image {
        id
        width
        height
        mime
        size
        url
      }
    }
  }
`;

/**
 * __useEventThemesQuery__
 *
 * To run a query within a React component, call `useEventThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventThemesQuery,
    EventThemesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventThemesQuery, EventThemesQueryVariables>(
    EventThemesDocument,
    options,
  );
}
export function useEventThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventThemesQuery,
    EventThemesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventThemesQuery, EventThemesQueryVariables>(
    EventThemesDocument,
    options,
  );
}
export type EventThemesQueryHookResult = ReturnType<typeof useEventThemesQuery>;
export type EventThemesLazyQueryHookResult = ReturnType<
  typeof useEventThemesLazyQuery
>;
export type EventThemesQueryResult = Apollo.QueryResult<
  EventThemesQuery,
  EventThemesQueryVariables
>;
export const ManagerDocument = gql`
  query Manager($managerId: Int!) {
    manager(id: $managerId) {
      id
      email
      firstName
      lastName
      position
      photo {
        id
        width
        height
        mime
        size
        url
      }
      roles
      brand {
        id
      }
    }
  }
`;

/**
 * __useManagerQuery__
 *
 * To run a query within a React component, call `useManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useManagerQuery(
  baseOptions: Apollo.QueryHookOptions<ManagerQuery, ManagerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManagerQuery, ManagerQueryVariables>(
    ManagerDocument,
    options,
  );
}
export function useManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManagerQuery,
    ManagerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManagerQuery, ManagerQueryVariables>(
    ManagerDocument,
    options,
  );
}
export type ManagerQueryHookResult = ReturnType<typeof useManagerQuery>;
export type ManagerLazyQueryHookResult = ReturnType<typeof useManagerLazyQuery>;
export type ManagerQueryResult = Apollo.QueryResult<
  ManagerQuery,
  ManagerQueryVariables
>;
export const ManagersDocument = gql`
  query Managers($filter: FindFilter) {
    managers(filter: $filter) {
      id
      email
      firstName
      lastName
      position
      photo {
        id
        width
        height
        mime
        size
        url
      }
      brand {
        id
        isActive
      }
      roles
    }
  }
`;

/**
 * __useManagersQuery__
 *
 * To run a query within a React component, call `useManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<ManagersQuery, ManagersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManagersQuery, ManagersQueryVariables>(
    ManagersDocument,
    options,
  );
}
export function useManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManagersQuery,
    ManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManagersQuery, ManagersQueryVariables>(
    ManagersDocument,
    options,
  );
}
export type ManagersQueryHookResult = ReturnType<typeof useManagersQuery>;
export type ManagersLazyQueryHookResult = ReturnType<
  typeof useManagersLazyQuery
>;
export type ManagersQueryResult = Apollo.QueryResult<
  ManagersQuery,
  ManagersQueryVariables
>;
export const OfferDocument = gql`
  query Offer($offerId: Int!) {
    offer(id: $offerId) {
      id
      name
      description
      scheduleType
      amountPersons
      eventThemes {
        id
        title
      }
      workDays {
        id
        day
        from
        to
        isActive
      }
      cuisines {
        title
        id
      }
      brand {
        id
      }
      budgetPerPerson
      image {
        id
        width
        height
        mime
        size
        url
      }
      restaurants {
        id
        name
      }
      offerDishes {
        quantity
        dishId
        offerId
        dish {
          id
          title
          description
          brandId
          image {
            id
            width
            height
            mime
            size
            url
          }
          price
          cookingTime
          amountPersons
          calories
          weight
          volume
        }
      }
    }
  }
`;

/**
 * __useOfferQuery__
 *
 * To run a query within a React component, call `useOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferQuery(
  baseOptions: Apollo.QueryHookOptions<OfferQuery, OfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferQuery, OfferQueryVariables>(
    OfferDocument,
    options,
  );
}
export function useOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OfferQuery, OfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferQuery, OfferQueryVariables>(
    OfferDocument,
    options,
  );
}
export type OfferQueryHookResult = ReturnType<typeof useOfferQuery>;
export type OfferLazyQueryHookResult = ReturnType<typeof useOfferLazyQuery>;
export type OfferQueryResult = Apollo.QueryResult<
  OfferQuery,
  OfferQueryVariables
>;
export const OfferByBrandDocument = gql`
  query OfferByBrand($offerByBrandId: Int!) {
    offerByBrand(id: $offerByBrandId) {
      id
      name
      description
      cuisines {
        title
        id
      }
      scheduleType
      budgetPerPerson
      image {
        url
      }
      offerDishes {
        quantity
        offerId
        dishId
        dish {
          calories
          description
          id
          image {
            url
          }
          price
          title
          volume
          weight
          amountPersons
          cookingTime
        }
      }
      workDays {
        day
        from
        id
        isActive
        to
      }
    }
  }
`;

/**
 * __useOfferByBrandQuery__
 *
 * To run a query within a React component, call `useOfferByBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferByBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferByBrandQuery({
 *   variables: {
 *      offerByBrandId: // value for 'offerByBrandId'
 *   },
 * });
 */
export function useOfferByBrandQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferByBrandQuery,
    OfferByBrandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferByBrandQuery, OfferByBrandQueryVariables>(
    OfferByBrandDocument,
    options,
  );
}
export function useOfferByBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferByBrandQuery,
    OfferByBrandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferByBrandQuery, OfferByBrandQueryVariables>(
    OfferByBrandDocument,
    options,
  );
}
export type OfferByBrandQueryHookResult = ReturnType<
  typeof useOfferByBrandQuery
>;
export type OfferByBrandLazyQueryHookResult = ReturnType<
  typeof useOfferByBrandLazyQuery
>;
export type OfferByBrandQueryResult = Apollo.QueryResult<
  OfferByBrandQuery,
  OfferByBrandQueryVariables
>;
export const OffersByBrandDocument = gql`
  query OffersByBrand($options: GetOffersAsManagerInput!) {
    offersByBrand(options: $options) {
      rows {
        workDays {
          id
          day
          from
          to
          isActive
        }
        scheduleType
        id
        name
        budgetPerPerson
        restaurants {
          address
        }
      }
      count
    }
  }
`;

/**
 * __useOffersByBrandQuery__
 *
 * To run a query within a React component, call `useOffersByBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersByBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersByBrandQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useOffersByBrandQuery(
  baseOptions: Apollo.QueryHookOptions<
    OffersByBrandQuery,
    OffersByBrandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OffersByBrandQuery, OffersByBrandQueryVariables>(
    OffersByBrandDocument,
    options,
  );
}
export function useOffersByBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OffersByBrandQuery,
    OffersByBrandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OffersByBrandQuery, OffersByBrandQueryVariables>(
    OffersByBrandDocument,
    options,
  );
}
export type OffersByBrandQueryHookResult = ReturnType<
  typeof useOffersByBrandQuery
>;
export type OffersByBrandLazyQueryHookResult = ReturnType<
  typeof useOffersByBrandLazyQuery
>;
export type OffersByBrandQueryResult = Apollo.QueryResult<
  OffersByBrandQuery,
  OffersByBrandQueryVariables
>;
export const RegistrationApplicationDocument = gql`
  query registrationApplication($id: Int!) {
    registrationApplication(id: $id) {
      id
      name
      email
      phone
      restaurantName
      country
      city
      createdAt
    }
  }
`;

/**
 * __useRegistrationApplicationQuery__
 *
 * To run a query within a React component, call `useRegistrationApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegistrationApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegistrationApplicationQuery,
    RegistrationApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegistrationApplicationQuery,
    RegistrationApplicationQueryVariables
  >(RegistrationApplicationDocument, options);
}
export function useRegistrationApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationApplicationQuery,
    RegistrationApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegistrationApplicationQuery,
    RegistrationApplicationQueryVariables
  >(RegistrationApplicationDocument, options);
}
export type RegistrationApplicationQueryHookResult = ReturnType<
  typeof useRegistrationApplicationQuery
>;
export type RegistrationApplicationLazyQueryHookResult = ReturnType<
  typeof useRegistrationApplicationLazyQuery
>;
export type RegistrationApplicationQueryResult = Apollo.QueryResult<
  RegistrationApplicationQuery,
  RegistrationApplicationQueryVariables
>;
export const RegistrationApplicationsDocument = gql`
  query registrationApplications($options: RegistrationApplicationsArgs!) {
    registrationApplications(options: $options) {
      rows {
        id
        name
        email
        phone
        restaurantName
        country
        city
        createdAt
      }
      count
    }
  }
`;

/**
 * __useRegistrationApplicationsQuery__
 *
 * To run a query within a React component, call `useRegistrationApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationApplicationsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRegistrationApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegistrationApplicationsQuery,
    RegistrationApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegistrationApplicationsQuery,
    RegistrationApplicationsQueryVariables
  >(RegistrationApplicationsDocument, options);
}
export function useRegistrationApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationApplicationsQuery,
    RegistrationApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegistrationApplicationsQuery,
    RegistrationApplicationsQueryVariables
  >(RegistrationApplicationsDocument, options);
}
export type RegistrationApplicationsQueryHookResult = ReturnType<
  typeof useRegistrationApplicationsQuery
>;
export type RegistrationApplicationsLazyQueryHookResult = ReturnType<
  typeof useRegistrationApplicationsLazyQuery
>;
export type RegistrationApplicationsQueryResult = Apollo.QueryResult<
  RegistrationApplicationsQuery,
  RegistrationApplicationsQueryVariables
>;
export const RemoveRestaurantDocument = gql`
  mutation RemoveRestaurant($removeRestaurantId: Int!) {
    removeRestaurant(id: $removeRestaurantId)
  }
`;
export type RemoveRestaurantMutationFn = Apollo.MutationFunction<
  RemoveRestaurantMutation,
  RemoveRestaurantMutationVariables
>;

/**
 * __useRemoveRestaurantMutation__
 *
 * To run a mutation, you first call `useRemoveRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRestaurantMutation, { data, loading, error }] = useRemoveRestaurantMutation({
 *   variables: {
 *      removeRestaurantId: // value for 'removeRestaurantId'
 *   },
 * });
 */
export function useRemoveRestaurantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRestaurantMutation,
    RemoveRestaurantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRestaurantMutation,
    RemoveRestaurantMutationVariables
  >(RemoveRestaurantDocument, options);
}
export type RemoveRestaurantMutationHookResult = ReturnType<
  typeof useRemoveRestaurantMutation
>;
export type RemoveRestaurantMutationResult =
  Apollo.MutationResult<RemoveRestaurantMutation>;
export type RemoveRestaurantMutationOptions = Apollo.BaseMutationOptions<
  RemoveRestaurantMutation,
  RemoveRestaurantMutationVariables
>;
export const RestaurantDocument = gql`
  query restaurant($id: Int!) {
    restaurant(id: $id) {
      id
      brandId
      name
      scheduleType
      priceLevel
      description
      averageCost
      address
      latitude
      longitude
      availableSeatsNumber
      isClosed
      closedFrom
      closedTo
      createdAt
      updatedAt
      image {
        id
        width
        height
        mime
        size
        url
      }
      city {
        id
        title
      }
      cuisines {
        id
        title
      }
      workDays {
        id
        day
        from
        to
        isActive
      }
      dishes {
        id
      }
      secondImage {
        id
        width
        height
        mime
        size
        url
      }
    }
  }
`;

/**
 * __useRestaurantQuery__
 *
 * To run a query within a React component, call `useRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestaurantQuery(
  baseOptions: Apollo.QueryHookOptions<
    RestaurantQuery,
    RestaurantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RestaurantQuery, RestaurantQueryVariables>(
    RestaurantDocument,
    options,
  );
}
export function useRestaurantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestaurantQuery,
    RestaurantQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RestaurantQuery, RestaurantQueryVariables>(
    RestaurantDocument,
    options,
  );
}
export type RestaurantQueryHookResult = ReturnType<typeof useRestaurantQuery>;
export type RestaurantLazyQueryHookResult = ReturnType<
  typeof useRestaurantLazyQuery
>;
export type RestaurantQueryResult = Apollo.QueryResult<
  RestaurantQuery,
  RestaurantQueryVariables
>;
export const RestaurantDetailWithoutBrandPageDocument = gql`
  query RestaurantDetailWithoutBrandPage(
    $id: Int!
    $dateFrom: String!
    $dateTo: String!
  ) {
    restaurant(id: $id) {
      id
      brandId
      name
      scheduleType
      priceLevel
      description
      averageCost
      address
      latitude
      longitude
      availableSeatsNumber
      isClosed
      closedFrom
      closedTo
      createdAt
      updatedAt
      image {
        id
        width
        height
        mime
        size
        url
      }
      city {
        id
        title
      }
      cuisines {
        id
        title
      }
      workDays {
        id
        day
        from
        to
        isActive
      }
      dishes {
        id
      }
      secondImage {
        id
        width
        height
        mime
        size
        url
      }
      viewCount(data: { from: $dateFrom, to: $dateTo })
      clickCount(data: { from: $dateFrom, to: $dateTo })
      requestCount(data: { from: $dateFrom, to: $dateTo })
    }
  }
`;

/**
 * __useRestaurantDetailWithoutBrandPageQuery__
 *
 * To run a query within a React component, call `useRestaurantDetailWithoutBrandPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantDetailWithoutBrandPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantDetailWithoutBrandPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useRestaurantDetailWithoutBrandPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    RestaurantDetailWithoutBrandPageQuery,
    RestaurantDetailWithoutBrandPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RestaurantDetailWithoutBrandPageQuery,
    RestaurantDetailWithoutBrandPageQueryVariables
  >(RestaurantDetailWithoutBrandPageDocument, options);
}
export function useRestaurantDetailWithoutBrandPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestaurantDetailWithoutBrandPageQuery,
    RestaurantDetailWithoutBrandPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RestaurantDetailWithoutBrandPageQuery,
    RestaurantDetailWithoutBrandPageQueryVariables
  >(RestaurantDetailWithoutBrandPageDocument, options);
}
export type RestaurantDetailWithoutBrandPageQueryHookResult = ReturnType<
  typeof useRestaurantDetailWithoutBrandPageQuery
>;
export type RestaurantDetailWithoutBrandPageLazyQueryHookResult = ReturnType<
  typeof useRestaurantDetailWithoutBrandPageLazyQuery
>;
export type RestaurantDetailWithoutBrandPageQueryResult = Apollo.QueryResult<
  RestaurantDetailWithoutBrandPageQuery,
  RestaurantDetailWithoutBrandPageQueryVariables
>;
export const RestaurantsDocument = gql`
  query Restaurants($getRestaurantsInput: GetRestaurantsInput!) {
    restaurants(getRestaurantsInput: $getRestaurantsInput) {
      rows {
        id
        brandId
        name
        scheduleType
        priceLevel
        description
        averageCost
        address
        latitude
        longitude
        availableSeatsNumber
        isClosed
        createdAt
        updatedAt
        image {
          id
          width
          height
          mime
          size
          url
        }
        city {
          id
          title
        }
        cuisines {
          id
          title
        }
        workDays {
          id
          day
          from
          to
          isActive
        }
      }
      count
    }
  }
`;

/**
 * __useRestaurantsQuery__
 *
 * To run a query within a React component, call `useRestaurantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantsQuery({
 *   variables: {
 *      getRestaurantsInput: // value for 'getRestaurantsInput'
 *   },
 * });
 */
export function useRestaurantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RestaurantsQuery,
    RestaurantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RestaurantsQuery, RestaurantsQueryVariables>(
    RestaurantsDocument,
    options,
  );
}
export function useRestaurantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestaurantsQuery,
    RestaurantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RestaurantsQuery, RestaurantsQueryVariables>(
    RestaurantsDocument,
    options,
  );
}
export type RestaurantsQueryHookResult = ReturnType<typeof useRestaurantsQuery>;
export type RestaurantsLazyQueryHookResult = ReturnType<
  typeof useRestaurantsLazyQuery
>;
export type RestaurantsQueryResult = Apollo.QueryResult<
  RestaurantsQuery,
  RestaurantsQueryVariables
>;
export const RestaurantWithoutBrandPageDocument = gql`
  query RestaurantWithoutBrandPage(
    $getRestaurantsInput: GetRestaurantsInput!
    $dateFrom: String!
    $dateTo: String!
  ) {
    restaurants(getRestaurantsInput: $getRestaurantsInput) {
      rows {
        id
        brandId
        name
        scheduleType
        priceLevel
        description
        averageCost
        address
        latitude
        longitude
        availableSeatsNumber
        isClosed
        createdAt
        updatedAt
        image {
          id
          width
          height
          mime
          size
          url
        }
        city {
          id
          title
        }
        cuisines {
          id
          title
        }
        workDays {
          id
          day
          from
          to
          isActive
        }
        viewCount(data: { from: $dateFrom, to: $dateTo })
        clickCount(data: { from: $dateFrom, to: $dateTo })
        requestCount(data: { from: $dateFrom, to: $dateTo })
      }
      count
    }
  }
`;

/**
 * __useRestaurantWithoutBrandPageQuery__
 *
 * To run a query within a React component, call `useRestaurantWithoutBrandPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantWithoutBrandPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantWithoutBrandPageQuery({
 *   variables: {
 *      getRestaurantsInput: // value for 'getRestaurantsInput'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useRestaurantWithoutBrandPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    RestaurantWithoutBrandPageQuery,
    RestaurantWithoutBrandPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RestaurantWithoutBrandPageQuery,
    RestaurantWithoutBrandPageQueryVariables
  >(RestaurantWithoutBrandPageDocument, options);
}
export function useRestaurantWithoutBrandPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RestaurantWithoutBrandPageQuery,
    RestaurantWithoutBrandPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RestaurantWithoutBrandPageQuery,
    RestaurantWithoutBrandPageQueryVariables
  >(RestaurantWithoutBrandPageDocument, options);
}
export type RestaurantWithoutBrandPageQueryHookResult = ReturnType<
  typeof useRestaurantWithoutBrandPageQuery
>;
export type RestaurantWithoutBrandPageLazyQueryHookResult = ReturnType<
  typeof useRestaurantWithoutBrandPageLazyQuery
>;
export type RestaurantWithoutBrandPageQueryResult = Apollo.QueryResult<
  RestaurantWithoutBrandPageQuery,
  RestaurantWithoutBrandPageQueryVariables
>;
export const SettingsDocument = gql`
  query Settings {
    settings {
      key
      value
    }
  }
`;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(
    SettingsDocument,
    options,
  );
}
export function useSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsQuery,
    SettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(
    SettingsDocument,
    options,
  );
}
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<
  typeof useSettingsLazyQuery
>;
export type SettingsQueryResult = Apollo.QueryResult<
  SettingsQuery,
  SettingsQueryVariables
>;
export const SwitchRestaurantClosedStatusDocument = gql`
  mutation SwitchRestaurantClosedStatus($switchRestaurantClosedStatusId: Int!) {
    switchRestaurantClosedStatus(id: $switchRestaurantClosedStatusId) {
      id
      isClosed
    }
  }
`;
export type SwitchRestaurantClosedStatusMutationFn = Apollo.MutationFunction<
  SwitchRestaurantClosedStatusMutation,
  SwitchRestaurantClosedStatusMutationVariables
>;

/**
 * __useSwitchRestaurantClosedStatusMutation__
 *
 * To run a mutation, you first call `useSwitchRestaurantClosedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchRestaurantClosedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchRestaurantClosedStatusMutation, { data, loading, error }] = useSwitchRestaurantClosedStatusMutation({
 *   variables: {
 *      switchRestaurantClosedStatusId: // value for 'switchRestaurantClosedStatusId'
 *   },
 * });
 */
export function useSwitchRestaurantClosedStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchRestaurantClosedStatusMutation,
    SwitchRestaurantClosedStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchRestaurantClosedStatusMutation,
    SwitchRestaurantClosedStatusMutationVariables
  >(SwitchRestaurantClosedStatusDocument, options);
}
export type SwitchRestaurantClosedStatusMutationHookResult = ReturnType<
  typeof useSwitchRestaurantClosedStatusMutation
>;
export type SwitchRestaurantClosedStatusMutationResult =
  Apollo.MutationResult<SwitchRestaurantClosedStatusMutation>;
export type SwitchRestaurantClosedStatusMutationOptions =
  Apollo.BaseMutationOptions<
    SwitchRestaurantClosedStatusMutation,
    SwitchRestaurantClosedStatusMutationVariables
  >;
export const TranslatesDocument = gql`
  query Translates($key: String, $localeId: Int, $localeCode: String) {
    translates(key: $key, localeId: $localeId, localeCode: $localeCode) {
      key
      strings {
        locale {
          id
          title
          code
        }
        string
      }
      texts {
        text
        locale {
          code
          id
          title
        }
      }
      images {
        image {
          id
          width
          height
          mime
          size
          url
        }
        locale {
          code
          id
          title
        }
      }
    }
  }
`;

/**
 * __useTranslatesQuery__
 *
 * To run a query within a React component, call `useTranslatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslatesQuery({
 *   variables: {
 *      key: // value for 'key'
 *      localeId: // value for 'localeId'
 *      localeCode: // value for 'localeCode'
 *   },
 * });
 */
export function useTranslatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TranslatesQuery,
    TranslatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TranslatesQuery, TranslatesQueryVariables>(
    TranslatesDocument,
    options,
  );
}
export function useTranslatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TranslatesQuery,
    TranslatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TranslatesQuery, TranslatesQueryVariables>(
    TranslatesDocument,
    options,
  );
}
export type TranslatesQueryHookResult = ReturnType<typeof useTranslatesQuery>;
export type TranslatesLazyQueryHookResult = ReturnType<
  typeof useTranslatesLazyQuery
>;
export type TranslatesQueryResult = Apollo.QueryResult<
  TranslatesQuery,
  TranslatesQueryVariables
>;
export const ManagerMeDocument = gql`
  query ManagerMe {
    managerMe {
      id
      email
      firstName
      lastName
      position
      photo {
        url
      }
      brand {
        id
      }
      roles
    }
  }
`;

/**
 * __useManagerMeQuery__
 *
 * To run a query within a React component, call `useManagerMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagerMeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManagerMeQuery,
    ManagerMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManagerMeQuery, ManagerMeQueryVariables>(
    ManagerMeDocument,
    options,
  );
}
export function useManagerMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManagerMeQuery,
    ManagerMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManagerMeQuery, ManagerMeQueryVariables>(
    ManagerMeDocument,
    options,
  );
}
export type ManagerMeQueryHookResult = ReturnType<typeof useManagerMeQuery>;
export type ManagerMeLazyQueryHookResult = ReturnType<
  typeof useManagerMeLazyQuery
>;
export type ManagerMeQueryResult = Apollo.QueryResult<
  ManagerMeQuery,
  ManagerMeQueryVariables
>;
export const NewEventOffersCountDocument = gql`
  subscription NewEventOffersCount {
    newEventOffersCount {
      count
    }
  }
`;

/**
 * __useNewEventOffersCountSubscription__
 *
 * To run a query within a React component, call `useNewEventOffersCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewEventOffersCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEventOffersCountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewEventOffersCountSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewEventOffersCountSubscription,
    NewEventOffersCountSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewEventOffersCountSubscription,
    NewEventOffersCountSubscriptionVariables
  >(NewEventOffersCountDocument, options);
}
export type NewEventOffersCountSubscriptionHookResult = ReturnType<
  typeof useNewEventOffersCountSubscription
>;
export type NewEventOffersCountSubscriptionResult =
  Apollo.SubscriptionResult<NewEventOffersCountSubscription>;
export const OutscrapperLocationsDocument = gql`
  query OutscrapperLocations($input: FindOutscrapperLocationsInput!) {
    outscrapperLocations(input: $input) {
      name
    }
  }
`;

/**
 * __useOutscrapperLocationsQuery__
 *
 * To run a query within a React component, call `useOutscrapperLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutscrapperLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutscrapperLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOutscrapperLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutscrapperLocationsQuery,
    OutscrapperLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OutscrapperLocationsQuery,
    OutscrapperLocationsQueryVariables
  >(OutscrapperLocationsDocument, options);
}
export function useOutscrapperLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutscrapperLocationsQuery,
    OutscrapperLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OutscrapperLocationsQuery,
    OutscrapperLocationsQueryVariables
  >(OutscrapperLocationsDocument, options);
}
export type OutscrapperLocationsQueryHookResult = ReturnType<
  typeof useOutscrapperLocationsQuery
>;
export type OutscrapperLocationsLazyQueryHookResult = ReturnType<
  typeof useOutscrapperLocationsLazyQuery
>;
export type OutscrapperLocationsQueryResult = Apollo.QueryResult<
  OutscrapperLocationsQuery,
  OutscrapperLocationsQueryVariables
>;
